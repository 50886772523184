import React, { Component } from "react";
import "./author-details.css";
import { API, Storage } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Modal, Progress, TextArea} from 'semantic-ui-react'


export default class author_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            author: {},
            didmount: false,
            author_updating: false,
            author_updateready: false,
            newauthorimage: {},
            newauthorimage_updateready: false,
            mdl_updateauthorimage_open: false,
            newauthorimage_updating: false,
            newauthorimage_updateprogress: 0,
            filename: ''
        };

    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateauthor()
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populateauthor(){
        try {
            let author = await this.API_get_author();
            author[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            author[0]['imageid']=this.props.match.params.authorid;
            this.setState({ author: author[0] });
        } catch (e) {
            console.log(e);
        }
    }

    async updateauthor(){
        try {
            await this.API_post_author(this.state.author);
            this.setState({ author_updating: false });
            this.setState({ author_updateready: false });
            this.populateauthor();
        } catch (e) {
            console.log(e);
        }
    }

    async updateauthorimage(){
        try {
            await this.API_post_authorimage(this.state.newauthorimage);
            this.setState({ newauthorimage_updating: false });
            this.setState({ newauthorimage_updateready: false });
            this.populateauthorimage();
        } catch (e) {
            console.log(e);
        }
    }

    async populateauthorimage(){
        try {
            let newauthorimage = await this.API_get_authorimage();
            this.setState({ newauthorimage: newauthorimage[0] });
            const { author } = { ...this.state };
            const currentState = author;
            currentState['lastmodified'] = this.state.newauthorimage.lastmodified;
            currentState['lastmodifiedby'] = this.state.newauthorimage.lastmodifiedby;
            currentState['imageid']=this.props.match.params.authorid;
            this.setState({ author: currentState });
            document.getElementById('authorimage').src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/author_"+this.props.match.params.authorid+".jpg";
            this.setState({ mdl_updateauthorimage_open: false })
        } catch (e) {
            console.log(e);
        }
    }


    API_get_author() {
        return API.get('tap-admin', '/blogs/get-author/'+this.props.match.params.authorid);
    }

    API_get_authorimage() {
        return API.get('tap-admin', '/blogs/get-authorimage/'+this.props.match.params.authorid);
    }

    API_post_author(body) {
        return API.post('tap-admin', '/blogs/post-author/'+this.props.match.params.authorid, { body });
    }

    API_post_authorimage(body) {
        return API.post('tap-admin', '/blogs/post-authorimage/'+this.props.match.params.authorid, { body });
    }

    onClick_btn_detailparent(){
        this.props.history.push('/blogs');
    }

    onChange_input = (event) => {
        const { author } = { ...this.state };
        const currentState = author;
        currentState[event.target.name] = event.target.value;
        this.setState({ author: currentState });
        this.setState({ author_updateready: true })
    };

    onOpen_mdl_updateauthorimage(){
        const { newauthorimage } = { ...this.state };
        const currentState = newauthorimage;
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newauthorimage: currentState });
        this.setState({ newauthorimage_updateready: false });
        this.setState({ mdl_updateauthorimage_open: true });
        this.setState({ newauthorimage_updateprogress: 0 });
        this.setState({ filename: '' });
    }

    onChange_file = event => {
        this.file = event.target.files[0];
        this.setState({ filename: this.file.name });
        this.setState({ newauthorimage_updateready: true });
    };

    async onClick_updateauthorimage(){
        this.setState({ newauthorimage_updating: true });
        document.getElementById('authorimage').src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg";

        const CustomFunc = progress => {
            this.setState({ newauthorimage_updateprogress: Math.round((progress.loaded/progress.total)*100) });
        };

        try {
            await Storage.put('jpg/author_'+this.state.author.id+'.jpg', this.file, {
                progressCallback: progress => {
                    CustomFunc(progress);
                },
            });
            this.updateauthorimage();
        } catch (e) {
            alert(e);
            this.setState({ newauthorimage_updating: false });
        }
    }

    onClose_mdl_updateauthorimage(){
        this.setState({ mdl_updateauthorimage_open: false })
    }

    async onClick_updateauthor(){
        this.setState({ author_updating: true });
        this.updateauthor();
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        const { editorState } = this.state;
        return (
            <div className="content">
                <div className="content-topmenu" id="author_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Blogs</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_authordetails">
                        <div className="content-card-header">
                            {this.state.author.firstname} {this.state.author.lastname}
                        </div>
                        {this.state.author &&
                        <div className="resource content-card-content">
                            <Form>
                                <Label>AUTHOR IMAGE (CLICK TO UPDATE)</Label>
                                <Form.Group>
                                    <div className="authorimage">
                                        <img id="authorimage"
                                             src={"https://tap-app-resources.s3.amazonaws.com/public/jpg/author_"+this.state.author.imageid+".jpg"}
                                             onError={this.addDefaultSrc}
                                             onClick={() => this.onOpen_mdl_updateauthorimage()}
                                             alt="author header"></img>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='author_firstname'
                                        id='firstname'
                                        name='firstname'
                                        value={this.state.author.firstname || ''}
                                        control={Input}
                                        label='FIRST NAME'
                                        placeholder='First name...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='author_lastname'
                                        id='lastname'
                                        name='lastname'
                                        value={this.state.author.lastname || ''}
                                        control={Input}
                                        label='LAST NAME'
                                        placeholder='Last name...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='author_role'
                                        id='role'
                                        name='role'
                                        value={this.state.author.role || ''}
                                        control={Input}
                                        label='ROLE'
                                        placeholder='Role...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='author_bio'
                                        id='bio'
                                        name='bio'
                                        value={this.state.author.bio || ''}
                                        control={TextArea}
                                        label='BIO'
                                        placeholder='Bio...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.author.lastmodifiedby} - {this.state.author.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button >CANCEL</Button>
                                {!this.state.author_updating
                                    ?
                                    <Button className={(this.state.author_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateauthor()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/**    MODAL: UPDATE AUTHOR IMAGE *******************************************************************/}

                <Modal className="mdl_updateblogimage"
                       dimmer={'inverted'}
                       open={this.state.mdl_updateauthorimage_open}
                       onClose={() => { this.setState({ mdl_updateauthorimage_open: false }) }}
                >
                    <div className="modal-header">Select A New Image</div>
                    <div className="modal-content">
                        {!this.state.newauthorimage_updating ?
                            <Form>
                                <Label>AUTHOR IMAGE FILE</Label>
                                <div id="btn_filebrowse" className="ui action input">
                                    <input type="text" placeholder="Image to upload..." value={this.state.filename}
                                           readOnly/>
                                    <button className="ui button" onClick={e => {
                                        this.upload.value = null;
                                        this.upload.click();
                                    }} loading={this.state.uploading}>
                                        BROWSE
                                    </button>
                                </div>
                                <input
                                    type="file"
                                    style={{display: "none"}}
                                    ref={ref => (this.upload = ref)}
                                    onChange={this.onChange_file}
                                />
                            </Form>
                            :
                            <Progress className="mdl_updateauthorimage_progressbar"
                                      percent={this.state.newauthorimage_updateprogress} progress/>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_updateauthorimage()}>CANCEL</Button>
                            {!this.state.newauthorimage_updating
                                ?
                                <Button className={(this.state.newauthorimage_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateauthorimage()}>UPDATE</Button>
                                :
                                <Button loading className='activestate'>UPDATE</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: UPDATE BLOCK IMAGE ****************************************************************/}

            </div>
        );
    }
}