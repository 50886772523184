import Routes from "./routes";
import React from "react";
import { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import './App.css';


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {},
      user_authenticated: false,
      user_authenticating: false,
      topmenu: 'content'
    };
  }



  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);

    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({user: JSON.parse(localStorage.getItem('user')) })
    this.setState({ user_authenticating: false });
  }



  //  set user_authenticated state
  userHasAuthenticated = authenticated => {
    this.setState({ user_authenticated: authenticated });
  };



  //  set user details object
  userHasDetails = user => {
    this.setState({ user: user });
    localStorage.setItem('user', JSON.stringify(user));

  };



  //  logout onClick handler
  onClick_logout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);
    this.props.history.push("/");
  };

  onClick_content = async event => {
    this.setState({ topmenu: 'content' });
    this.props.history.push('/resources/');
  };

  onClick_tools = async event => {
    this.setState({ topmenu: 'tools' });
  };

  onClick_conversations = async event => {
    this.setState({ topmenu: 'conversations' });
    this.props.history.push('/conversations/');
  };

  onClick_analytics = async event => {
    this.setState({ topmenu: 'analytics' });
  };

  onClick_members = async event => {
    this.setState({ topmenu: 'members' });
    this.props.history.push('/members/');
  };

  onClick_payment = async event => {
    this.setState({ topmenu: 'payment' });
  };


  onClick_resources = async event => {
    this.props.history.push('/resources/');
  };

  onClick_topics = async event => {
    this.props.history.push('/topics/');
  };

  onClick_blocks = async event => {
    this.props.history.push('/blocks/');
  };

  onClick_modules = async event => {
    this.props.history.push('/modules/');
  };

  onClick_landingpages = async event => {
    this.props.history.push('/landingpages/');
  };

  onClick_learningpaths = async event => {
    this.props.history.push('/learningpaths/');
  };

  onClick_blogs = async event => {
    this.props.history.push('/blogs/');
  };

  onClick_allconversations = async event => {
    this.props.history.push('/conversations/');
  };

  onClick_members_members = async event => {
    this.props.history.push('/members/');
  };

  onClick_members_demoaccounts = async event => {
    this.props.history.push('/demoaccounts/');
  };


//  render app ---------------------------------------------------------------------------------------------------------
  render() {

    const childProps = {
      user_authenticated: this.state.user_authenticated,
      user_authenticating: this.state.user_authenticating,
      userHasAuthenticated: this.userHasAuthenticated,
      userHasDetails: this.userHasDetails
    };

    return (
        !this.state.user_authenticating &&
        <div>
          {this.state.user_authenticated

              ? <div className="app-container">
                <div className="app-topmenu">
                  <div className="app-topmenu-logo">
                    <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img>
                  </div>
                  <div className="app-topmenu-menu">
                    <div className="app-topmenu-menuitem" onClick={this.onClick_content}>Content</div>
                    <div className="app-topmenu-menuitem" onClick={this.onClick_conversations}>Conversations</div>
                    {/*<div className="app-topmenu-menuitem" onClick={this.onClick_tools}>Tools</div>*/}
                    {/*<div className="app-topmenu-menuitem" onClick={this.onClick_analytics}>Analytics</div>*/}
                    <div className="app-topmenu-menuitem" onClick={this.onClick_members}>Members</div>
                    {/*<div className="app-topmenu-menuitem" onClick={this.onClick_payment}>Payment</div>*/}
                  </div>
                  <div className="app-topmenu-loggedin">
                    <div className="app-topmenu-loggedin-username">{this.state.user.firstname} {this.state.user.lastname}</div>
                    <div className="app-topmenu-loggedin-logout" onClick={this.onClick_logout}>Logout</div>
                  </div>
                </div>

                <div className="app-content">
                  <div className="app-sidenav">
                    {this.state.topmenu === 'content' &&
                      <div>
                        <div className="app-sidenav-header">CONTENT</div>
                        <div className="app-sidenav-item" onClick={this.onClick_resources}>Resources</div>
                        <div className="app-sidenav-item" onClick={this.onClick_topics}>Topics</div>
                        <div className="app-sidenav-item" onClick={this.onClick_blocks}>Blocks</div>
                        <div className="app-sidenav-item" onClick={this.onClick_modules}>Modules</div>
                        <div className="app-sidenav-item" onClick={this.onClick_learningpaths}>Learning Paths</div>
                        <div className="app-sidenav-item" onClick={this.onClick_landingpages}>Landing Pages</div>
                        <div className="app-sidenav-item" onClick={this.onClick_blogs}>Blogs</div>
                      </div>
                    }
                    {this.state.topmenu === 'conversations' &&
                      <div>
                        <div className="app-sidenav-header">CONVERSATIONS</div>
                        <div className="app-sidenav-item" onClick={this.onClick_allconversations}>All Conversations</div>
                      </div>
                    }
                    {/*{this.state.topmenu === 'tools' &&
                      <div>
                        <div className="app-sidenav-header">TOOLS</div>
                      </div>
                    }
                    {this.state.topmenu === 'analytics' &&
                      <div>
                        <div className="app-sidenav-header">ANALYTICS</div>
                      </div>
                    }*/}
                    {this.state.topmenu === 'members' &&
                      <div>
                        <div className="app-sidenav-header">MEMBERS</div>
                        <div className="app-sidenav-item" onClick={this.onClick_members_members}>Members</div>
                        {/*<div className="app-sidenav-item" onClick={this.onClick_members_demoaccounts}>Demo Accounts</div>*/}
                      </div>
                    }
                    {/*{this.state.topmenu === 'payment' &&
                      <div>
                        <div className="app-sidenav-header">PAYMENT</div>
                      </div>
                    }*/}
                  </div>
                  <div>
                    <Routes childProps={childProps} />
                  </div>
                </div>
              </div>

              :   <Routes childProps={childProps} />

          }
        </div>
    );
  }
}

export default withRouter(App);
