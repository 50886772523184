import React, { Component } from "react";
import "./blog-details.css";
import { API, Storage } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Modal, Progress, TextArea} from 'semantic-ui-react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../react-draft-wysiwyg.css';


export default class blog_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blog: {},
            editorState: EditorState.createEmpty(),
            didmount: false,
            blog_updating: false,
            blog_updateready: false,
            newblogimage: {},
            newblogimage_updateready: false,
            mdl_updateblogimage_open: false,
            newblogimage_updating: false,
            mdl_deleteblog_updating: false,
            mdl_deleteblog_open: false,
            newblogimage_updateprogress: 0,
            filename: ''
        };

    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateblog()
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populateblog(){
        try {
            let blog = await this.API_get_blog();
            blog[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            blog[0]['imageid']=this.props.match.params.blogid;
            this.setState({ blog: blog[0] });
            if(this.state.blog.content) {
                this.setState({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.state.blog.content).contentBlocks)) });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updateblog(){
        try {
            await this.API_post_blog(this.state.blog);
            this.setState({ blog_updating: false });
            this.setState({ blog_updateready: false });
            this.populateblog();
        } catch (e) {
            console.log(e);
        }
    }

    async updateblogimage(){
        try {
            await this.API_post_blogimage(this.state.newblogimage);
            this.setState({ newblogimage_updating: false });
            this.setState({ newblogimage_updateready: false });
            this.populateblogimage();
        } catch (e) {
            console.log(e);
        }
    }

    async populateblogimage(){
        try {
            let newblogimage = await this.API_get_blogimage();
            this.setState({ newblogimage: newblogimage[0] });
            const { blog } = { ...this.state };
            const currentState = blog;
            currentState['lastmodified'] = this.state.newblogimage.lastmodified;
            currentState['lastmodifiedby'] = this.state.newblogimage.lastmodifiedby;
            currentState['imageid']=this.props.match.params.blogid;
            this.setState({ blog: currentState });
            document.getElementById('blogimage').src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/blog_"+this.props.match.params.blogid+".jpg";
            this.setState({ mdl_updateblogimage_open: false })
        } catch (e) {
            console.log(e);
        }
    }


    API_get_blog() {
        return API.get('tap-admin', '/blogs/get-blog/'+this.props.match.params.blogid);
    }

    API_delete_blog() {
        return API.del('tap-admin', '/blogs/delete-blog/'+this.props.match.params.blogid);
    }

    API_get_blogimage() {
        return API.get('tap-admin', '/blogs/get-blogimage/'+this.props.match.params.blogid);
    }

    API_post_blog(body) {
        return API.post('tap-admin', '/blogs/post-blog/'+this.props.match.params.blogid, { body });
    }

    API_post_blogimage(body) {
        return API.post('tap-admin', '/blogs/post-blogimage/'+this.props.match.params.blogid, { body });
    }

    onClick_btn_detailparent(){
        this.props.history.push('/blogs');
    }

    onChange_editor = (editorState) => {
        this.setState({ editorState });
        const { blog } = { ...this.state };
        const currentState = blog;
        currentState['content'] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState({ blog: currentState });
        this.setState({ blog_updateready: true })
    };

    onChange_radio = (e, { name,value }) => {
        const { blog } = { ...this.state };
        const currentState = blog;
        currentState[name] = value;
        this.setState({ blog: currentState });
        this.setState({ blog_updateready: true })
    };

    onChange_input = (event) => {
        const { blog } = { ...this.state };
        const currentState = blog;
        currentState[event.target.name] = event.target.value;
        this.setState({ blog: currentState });
        this.setState({ blog_updateready: true })
    };

    onOpen_mdl_updateblogimage(){
        const { newblogimage } = { ...this.state };
        const currentState = newblogimage;
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newblogimage: currentState });
        this.setState({ newblogimage_updateready: false });
        this.setState({ mdl_updateblogimage_open: true });
        this.setState({ newblogimage_updateprogress: 0 });
        this.setState({ filename: '' });
    }

    onChange_file = event => {
        this.file = event.target.files[0];
        this.setState({ filename: this.file.name });
        this.setState({ newblogimage_updateready: true });
    };

    async onClick_updateblogimage(){
        this.setState({ newblogimage_updating: true });
        document.getElementById('blogimage').src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg";

        const CustomFunc = progress => {
            this.setState({ newblogimage_updateprogress: Math.round((progress.loaded/progress.total)*100) });
        };

        try {
            await Storage.put('jpg/blog_'+this.state.blog.id+'.jpg', this.file, {
                progressCallback: progress => {
                    CustomFunc(progress);
                },
            });
            this.updateblogimage();
        } catch (e) {
            alert(e);
            this.setState({ newblogimage_updating: false });
        }
    }

    onClose_mdl_updateblogimage(){
        this.setState({ mdl_updateblogimage_open: false })
    }

    onClick_btn_viewblog(){
        window.open('https://tapadvance.net/blog/'+this.props.match.params.blogid, "_blank")
    }

    async onClick_updateblog(){
        this.setState({ blog_updating: true });
        this.updateblog();
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }

    onClick_delete(){
        this.setState({mdl_deleteblog_updating: false});
        this.setState({mdl_deleteblog_open: true});
    }

    async onClick_deleteblog(){
        this.setState({mdl_deleteblog_updating: true})
        try {
            await this.API_delete_blog();
            this.setState({mdl_deleteblog_updating: false})
            this.setState({mdl_deleteblog_open: false});
            this.props.history.push('/blogs');
        } catch (e) {
            alert(e);
            this.setState({mdl_deleteblog_updating: false})
            this.setState({mdl_deleteblog_open: false});
        }
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        const { editorState } = this.state;
        return (
            <div className="content">
                <div className="content-topmenu" id="blog_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Blogs</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_blogdetails">
                        <div className="content-card-header">
                            {this.state.blog.blog_name}
                            <div className="content-card-header-buttons" onClick={() => this.onClick_btn_viewblog()}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                        </div>
                        {this.state.blog &&
                        <div className="resource content-card-content">
                            <Form>
                                <Label>BLOG STATUS</Label>
                                <Form.Group>
                                    <Form.Field
                                        control={Radio}
                                        label='Active'
                                        value='ACTIVE'
                                        name='status'
                                        checked={this.state.blog.status === 'ACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='Inactive'
                                        value='INACTIVE'
                                        name='status'
                                        checked={this.state.blog.status === 'INACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                </Form.Group>
                                <Label>BLOG IMAGE (CLICK TO UPDATE)</Label>
                                <Form.Group>
                                    <div className="blogimage">
                                        <img id="blogimage"
                                             src={"https://tap-app-resources.s3.amazonaws.com/public/jpg/blog_"+this.state.blog.imageid+".jpg"}
                                             onError={this.addDefaultSrc}
                                             onClick={() => this.onOpen_mdl_updateblogimage()}
                                             alt="blog header"></img>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='blog_name'
                                        id='blog'
                                        name='blog_name'
                                        value={this.state.blog.blog_name || ''}
                                        control={Input}
                                        label='BLOG NAME'
                                        placeholder='Blog name...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='blog_description_short'
                                        id='description_short'
                                        name='description_short'
                                        value={this.state.blog.description_short || ''}
                                        control={TextArea}
                                        label='DESCRIPTION (SHORT)'
                                        placeholder='Short description...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <Label>CONTENT</Label>
                                <div>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        toolbarClassName="demo-toolbar"
                                        onEditorStateChange={this.onChange_editor}
                                        defaultEditorState={this.state.blog.content}
                                    />
                                </div>
                            </Form>
                        </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.blog.lastmodifiedby} - {this.state.blog.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button >CANCEL</Button>
                                <Button onClick={() => this.onClick_delete()}>DELETE</Button>
                                {!this.state.blog_updating
                                    ?
                                    <Button className={(this.state.blog_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateblog()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/**    MODAL: UPDATE BLOCK IMAGE ********************************************************************/}

                <Modal className="mdl_updateblogimage"
                       dimmer={'inverted'}
                       open={this.state.mdl_updateblogimage_open}
                       onClose={() => { this.setState({ mdl_updateblogimage_open: false }) }}
                >
                    <div className="modal-header">Select A New Image</div>
                    <div className="modal-content">
                        {!this.state.newblogimage_updating ?
                            <Form>
                                <Label>BLOG IMAGE FILE</Label>
                                <div id="btn_filebrowse" className="ui action input">
                                    <input type="text" placeholder="Image to upload..." value={this.state.filename}
                                           readOnly/>
                                    <button className="ui button" onClick={e => {
                                        this.upload.value = null;
                                        this.upload.click();
                                    }} loading={this.state.uploading}>
                                        BROWSE
                                    </button>
                                </div>
                                <input
                                    type="file"
                                    style={{display: "none"}}
                                    ref={ref => (this.upload = ref)}
                                    onChange={this.onChange_file}
                                />
                            </Form>
                            :
                            <Progress className="mdl_updateblogimage_progressbar"
                                      percent={this.state.newblogimage_updateprogress} progress/>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_updateblogimage()}>CANCEL</Button>
                            {!this.state.newblogimage_updating
                                ?
                                <Button className={(this.state.newblogimage_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateblogimage()}>UPDATE</Button>
                                :
                                <Button loading className='activestate'>UPDATE</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: UPDATE BLOCK IMAGE ****************************************************************/}

                {/**    MODAL: DELETE BLOG ***************************************************************************/}

                <Modal id="mdl_deleteblog"
                       dimmer={'inverted'}
                       open={this.state.mdl_deleteblog_open}
                       onClose={() => { this.setState({ mdl_deleteblog_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Delete this blog</div>
                    <div className="modal-content">
                        Do you really want to delete this blog?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.setState({ mdl_deleteblog_open: false })}>NO</Button>
                            {!this.state.deleteblog_updating
                                ?   <Button className='activestate' onClick={() => this.onClick_deleteblog()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: DELETE BLOG ***********************************************************************/}

            </div>
        );
    }
}