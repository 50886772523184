import React, { Component } from "react";
import "./conversations.css";
import { API } from "aws-amplify";
import {Button, Form, Input, Label, Modal, Radio, Table} from 'semantic-ui-react'


export default class conversations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            conversations: [],
            didmount: false,
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateconversations();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populateconversations(){
        try {
            let conversations = await this.API_get_conversations();
            this.setState({ conversations });
        } catch (e) {
            console.log(e);
        }
    }

    API_get_conversations() {
        return API.get('tap-admin', '/conversations/get-conversations');
    }

    tableRowClickFunc(block_id,module_id,learningpath_id){
        if(block_id>0){
            window.open('https://tapadvance.net/block/'+block_id, "_blank")
        }
        if(module_id>0){
            window.open('https://tapadvance.net/module/'+module_id, "_blank")
        }
        if(learningpath_id>0){
            window.open('https://tapadvance.net/learningpath/'+learningpath_id, "_blank")
        }
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Conversations</div>
                    {/*<Button onClick={() => this.onOpen_mdl_createblock()}>NEW BLOCK</Button>*/}
                </div>
                <div className="content-wrapper">
                    <div className="content-card">
                        <div className="content-card-header">
                            All Conversations
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>TIMESTAMP</Table.HeaderCell>
                                        <Table.HeaderCell>FORUM TYPE</Table.HeaderCell>
                                        <Table.HeaderCell>FORUM NAME</Table.HeaderCell>
                                        <Table.HeaderCell>MEMBER</Table.HeaderCell>
                                        <Table.HeaderCell>COMMENT</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.conversations.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.block_id,item.module_id,item.learningpath_id)}>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            {item.block_id>0 && <Table.Cell>Block</Table.Cell>}
                                            {item.module_id>0 && <Table.Cell>Module</Table.Cell>}
                                            {item.learningpath_id>0 && <Table.Cell>Learning Path</Table.Cell>}
                                            {item.block_id>0 && <Table.Cell>{item.blockname}</Table.Cell>}
                                            {item.module_id>0 && <Table.Cell>{item.modulename}</Table.Cell>}
                                            {item.learningpath_id>0 && <Table.Cell>{item.learningpathname}</Table.Cell>}
                                            <Table.Cell>{item.member}</Table.Cell>
                                            <Table.Cell>{item.comment && item.comment.substring(0,60)+'...'}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}