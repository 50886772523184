import React, { Component } from "react";
import "./module-details.css";
import { API } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Dropdown, Table, TextArea, Modal} from 'semantic-ui-react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../react-draft-wysiwyg.css';

export default class module_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            module: {},
            moduleblock: {},
            moduleblocks: [],
            blocksdd: [],
            blockorderdd: [],
            editorState: EditorState.createEmpty(),
            mdl_addblock_open: false,
            didmount: false,
            module_updating: false,
            module_updateready: false,
            moduleblock_updating: false,
            moduleblock_updateready: false,
            mdl_removeblockfrommodule_open: false,
            removeblockfrommodule_updating: false,
            removeblockfrommodule_updateready: false,
            mdl_deletemodule_updating: false,
            mdl_deletemodule_open: false,
            removeblock: '',
            removeblockid: ''
        };

    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populatemodule();
            this.populatemoduleblocks();
            this.populateblocksdd();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populatemodule(){
        try {
            let module = await this.API_get_module();
            module[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ module: module[0] });
            if(this.state.module.description) {
                this.setState({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.state.module.description).contentBlocks)) });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updatemodule(){
        try {
            await this.API_post_module(this.state.module);
            this.setState({ module_updating: false });
            this.setState({ module_updateready: false });
            this.populatemodule();
        } catch (e) {
            console.log(e);
        }
    }

    async populatemoduleblocks(){
        try {
            let moduleblocks = await this.API_get_moduleblocks();
            this.setState({ moduleblocks });

            let tempblockorderdd = [];
            for(let i = 1; i < moduleblocks.length + 2; i++){
                tempblockorderdd.push({value:i,text:i})
            }
            this.setState({ blockorderdd: tempblockorderdd });

        } catch (e) {
            console.log(e);
        }
    }

    async populatemoduleblock(block_id){
        let moduleblock = await this.API_get_moduleblock(block_id);
        moduleblock[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ moduleblock: moduleblock[0] });
    }

    async insertmoduleblock(){
        this.setState({ moduleblock_updating: true });
        try {
            const { moduleblock } = { ...this.state };
            const currentState = moduleblock;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ moduleblock: currentState });

            await this.API_put_moduleblock(this.state.moduleblock);
            this.setState({ moduleblock_updating: false });
            this.setState({ moduleblock_updateready: false });
            this.onClose_mdl_addblock();
            this.populatemoduleblocks();
        } catch (e) {
            console.log(e);
        }
    }


    async onClick_updatemoduleblock(){
        this.setState({ moduleblock_updating: true });
        try {
            const { moduleblock } = { ...this.state };
            const currentState = moduleblock;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ moduleblock: currentState });

            await this.API_post_moduleblock(this.state.moduleblock);
            this.setState({ moduleblock_updating: false });
            this.setState({ moduleblock_updateready: false });
            this.onClose_mdl_addblock();
            this.populatemoduleblocks();
        } catch (e) {
            console.log(e);
        }
    }


    async populateblocksdd(){
        try {
            let blocksdd = await this.API_get_blocksdd();
            this.setState({ blocksdd });
        } catch (e) {
            console.log(e);
        }
    }


    API_get_module() {
        return API.get('tap-admin', '/modules/get-module/'+this.props.match.params.moduleid);
    }

    API_post_module(body) {
        return API.post('tap-admin', '/modules/post-module/'+this.props.match.params.moduleid, { body });
    }

    API_delete_module() {
        return API.del('tap-admin', '/modules/delete-module/'+this.props.match.params.moduleid);
    }

    API_get_moduleblocks() {
        return API.get('tap-admin', '/modules/get-moduleblocks/'+this.props.match.params.moduleid);
    }

    API_get_moduleblock(block_id) {
        return API.get('tap-admin', '/modules/get-moduleblock/'+block_id);
    }

    API_del_moduleblock() {
        return API.del('tap-admin', '/modules/del-moduleblock/'+this.state.removeblockid);
    }


    API_put_moduleblock(body) {
        return API.put('tap-admin', '/modules/put-moduleblock/'+this.props.match.params.moduleid, { body });
    }

    API_post_moduleblock(body) {
        return API.post('tap-admin', '/modules/post-moduleblock/'+this.state.moduleblock.id, { body });
    }

    API_get_blocksdd() {
        return API.get('tap-admin', '/blocks/get-blocksdd');
    }


    onClick_btn_detailparent(){
        this.props.history.push('/modules');
    }

    onChange_editor = (editorState) => {
        this.setState({ editorState });
        const { module } = { ...this.state };
        const currentState = module;
        currentState['description'] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState({ module: currentState });
        this.setState({ module_updateready: true })
    };

    onChange_radio = (e, { name,value }) => {
        const { module } = { ...this.state };
        const currentState = module;
        currentState[name] = value;
        this.setState({ module: currentState });
        this.setState({ module_updateready: true })
    };

    onChange_radio_mdl_addblock = (e, { name,value }) => {
        const { moduleblock } = { ...this.state };
        const currentState = moduleblock;
        currentState[name] = value;
        this.setState({ moduleblock: currentState });
        this.setState({ moduleblock_updateready: true })
    };

    onChange_input = (event) => {
        const { module } = { ...this.state };
        const currentState = module;
        currentState[event.target.name] = event.target.value;
        this.setState({ module: currentState });
        this.setState({ module_updateready: true })
    };

    onChange_input_moduleblock = (event) => {
        const { moduleblock } = { ...this.state };
        const currentState = moduleblock;
        currentState[event.target.name] = event.target.value;
        this.setState({ moduleblock: currentState });
        this.setState({ moduleblock_updateready: true })
    };

    onChange_dropdown = (e, { name,value }) => {
        const { moduleblock } = { ...this.state };
        const currentState = moduleblock;
        currentState[name] = value;
        this.setState({ moduleblock: currentState });
        this.setState({ moduleblock_updateready: true })
    };

    onClick_viewblock(block_id){
        this.props.history.push('/block-details/'+block_id);
    }

    onClick_editblock(block_id){
        this.setState({ mdl_addblock_mode: 'UPDATE' })
        this.setState({ moduleblock_updateready: false });
        this.setState({ mdl_addblock_open: true })
        this.populatemoduleblock(block_id)
    }

    onOpen_mdl_addblock(){
        const { moduleblock } = { ...this.state };
        const currentState = moduleblock;
        currentState['viewstatus'] = 'PAID';
        currentState['block_id'] = '';
        currentState['block_order'] = '';
        this.setState({ moduleblock: currentState });

        this.setState({ mdl_addblock_mode: 'INSERT' })
        this.setState({ moduleblock_updateready: false });
        this.setState({ mdl_addblock_open: true });
    }

    onClose_mdl_addblock(){
        this.setState({ mdl_addblock_open: false })
    }

    onClick_btn_viewmodule(){
        window.open('https://tapadvance.net/module/'+this.props.match.params.moduleid, "_blank")
    }

    onClick_removeblockfrommodule(event, removeblockid, removeblock, ){
        event.stopPropagation();
        this.setState({ removeblock: removeblock });
        this.setState({ removeblockid: removeblockid });
        this.setState({ removeblockfrommodule_updateready: true })
        this.setState({ mdl_removeblockfrommodule_open: true })
    }

    async onClick_deleteblockfrommodule(){
        this.setState({ removeblockfrommodule_updating: true });
        try {
            await this.API_del_moduleblock();
            this.setState({ removeblockfrommodule_updating: false });
            this.setState({ removeblockfrommodule_updateready: false });
            this.setState({ mdl_removeblockfrommodule_open: false })
            this.populatemoduleblocks();
        } catch (e) {
            console.log(e);
        }
    }

    onClose_mdl_removeblockfrommodule(){
        this.setState({ mdl_removeblockfrommodule_open: false })
    }


    async onClick_updatemodule(){
        this.setState({ module_updating: true });
        this.updatemodule();
    }

    async onClick_insertmoduleblock(){
        this.setState({ moduleblock_updating: true });
        this.insertmoduleblock();
    }

    onClick_delete(){
        this.setState({mdl_deletemodule_updating: false});
        this.setState({mdl_deletemodule_open: true});
    }

    async onClick_deletemodule(){
        this.setState({mdl_deletemodule_updating: true})
        try {
            await this.API_delete_module();
            this.setState({mdl_deletemodule_updating: false})
            this.setState({mdl_deletemodule_open: false});
            this.props.history.push('/modules');
        } catch (e) {
            alert(e);
            this.setState({mdl_deletemodule_updating: false})
            this.setState({mdl_deletemodule_open: false});
        }
    }


    
    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        const { editorState } = this.state;
        return (
            <div className="content">
                <div className="content-topmenu" id="module_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Modules</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_blockdetails">
                        <div className="content-card-header">
                            {this.state.module.module_name}
                            <div className="content-card-header-buttons" onClick={() => this.onClick_btn_viewmodule()}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                        </div>
                        {this.state.module &&
                        <div className="resource content-card-content">
                            <Form>
                                <Label>MODULE STATUS</Label>
                                <Form.Group>
                                    <Form.Field
                                        control={Radio}
                                        label='Active'
                                        value='ACTIVE'
                                        name='status'
                                        checked={this.state.module.status === 'ACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='Inactive'
                                        value='INACTIVE'
                                        name='status'
                                        checked={this.state.module.status === 'INACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='module'
                                        id='module'
                                        name='module_name'
                                        value={this.state.module.module_name || ''}
                                        control={Input}
                                        label='MODULE NAME'
                                        placeholder='Module name...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='module'
                                        id='description_short'
                                        name='description_short'
                                        value={this.state.module.description_short || ''}
                                        control={TextArea}
                                        label='DESCRIPTION (SHORT)'
                                        placeholder='Short description...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <div>
                                    <Label>DESCRIPTION</Label>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        toolbarClassName="demo-toolbar"
                                        onEditorStateChange={this.onChange_editor}
                                        defaultEditorState={this.state.module.description}
                                        toolbar={{
                                            options: ['inline', 'history'],
                                            inline: {options: ['bold', 'italic', 'underline',]},
                                        }}
                                    />
                                </div>
                            </Form>
                        </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.module.lastmodifiedby} - {this.state.module.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button >CANCEL</Button>
                                {this.state.module.blocks === 0 &&
                                    <Button onClick={() => this.onClick_delete()}>DELETE</Button>
                                }
                                {!this.state.module_updating
                                    ?
                                    <Button className={(this.state.module_updateready ? 'activestate' : '')} onClick={() => this.onClick_updatemodule()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>


                    <div className="content-card" id="cc_moduleblocks">
                        <div className="content-card-header">
                            Blocks assigned to this module
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_order">ORDER</Table.HeaderCell>
                                        <Table.HeaderCell>BLOCK</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_viewstatus">VIEW STATUS</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.moduleblocks.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.onClick_editblock(item.id)}>
                                            <Table.Cell className="tbl_order">{item.block_order}</Table.Cell>
                                            <Table.Cell>{item.block_name}</Table.Cell>
                                            <Table.Cell className="tbl_viewstatus">{item.viewstatus}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={event => this.onClick_removeblockfrommodule(event, item.id, item.block_name)}><img className="table_statusicon" src="/icons/x (e22d30).svg" alt="delete item icon"></img></Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewblock(item.block_id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified"></div>
                            <div className="content-card-footer-buttons">
                                <Button onClick={() => this.onOpen_mdl_addblock()}>ADD A BLOCK</Button>
                            </div>
                        </div>
                    </div>
                </div>


                {/**    MODAL: ADD BLOCK *****************************************************************************/}

                <Modal className="mdl_addblock"
                       dimmer={'inverted'}
                       open={this.state.mdl_addblock_open}
                       onClose={() => { this.setState({ mdl_addblock_open: false }) }}
                >
                    {this.state.mdl_addblock_mode === 'INSERT'
                        ?
                            <div className="modal-header">Add a block to this module</div>
                        :
                            <div className="modal-header">Update this block</div>
                    }
                    <div className="modal-content">
                        <Form>
                            <Label>VIEW STATUS</Label>
                            <Form.Group>
                                <Form.Field
                                    control={Radio}
                                    label='Paid'
                                    value='PAID'
                                    name='viewstatus'
                                    checked={this.state.moduleblock.viewstatus === 'PAID'}
                                    onChange={this.onChange_radio_mdl_addblock}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Free'
                                    value='FREE'
                                    name='viewstatus'
                                    checked={this.state.moduleblock.viewstatus === 'FREE'}
                                    onChange={this.onChange_radio_mdl_addblock}
                                />
                            </Form.Group>
                            <Label>BLOCK ORDER</Label>
                            <Form.Group className="block_order">
                                <Dropdown
                                    id='block_order'
                                    placeholder='0'
                                    fluid
                                    selection
                                    value={this.state.moduleblock.block_order}
                                    name='block_order'
                                    onChange={this.onChange_dropdown}
                                    options={this.state.blockorderdd}
                                />
                            </Form.Group>
                            <Label>BLOCK</Label>
                            <Form.Group className="dd_blocks">
                                <Dropdown
                                    id='dd_blocks'
                                    placeholder='select a block'
                                    fluid
                                    selection
                                    value={this.state.moduleblock.block_id}
                                    name='block_id'
                                    onChange={this.onChange_dropdown}
                                    options={this.state.blocksdd}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_addblock()}>CANCEL</Button>
                            {!this.state.moduleblock_updating
                                ?   (   this.state.mdl_addblock_mode === 'INSERT'
                                        ?
                                            <Button className={(this.state.moduleblock_updateready ? 'activestate' : '')} onClick={() => this.onClick_insertmoduleblock()}>ADD BLOCK</Button>
                                        :
                                            <Button className={(this.state.moduleblock_updateready ? 'activestate' : '')} onClick={() => this.onClick_updatemoduleblock()}>UPDATE</Button>
                                    )
                                :   (   this.state.mdl_addblock_mode === 'UPDATE'
                                        ?
                                            <Button loading className='activestate'>ADD BLOCK</Button>
                                        :
                                            <Button loading className='activestate'>UPDATE</Button>
                                    )
                            }

                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: ADD BLOCK *************************************************************************/}

                {/**    MODAL: REMOVE BLOCK FROM MODULE **************************************************************/}

                <Modal id="mdl_removeblockfrommodule"
                       dimmer={'inverted'}
                       open={this.state.mdl_removeblockfrommodule_open}
                       onClose={() => { this.setState({ mdl_removeblockfrommodule_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove block from module</div>
                    <div className="modal-content">
                        Do you really want to remove '{this.state.removeblock}' from the module '{this.state.module.module_name}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removeblockfrommodule()}>NO</Button>
                            {!this.state.removeblockfrommodule_updating
                                ?   <Button className={(this.state.removeblockfrommodule_updateready ? 'activestate' : '')} onClick={() => this.onClick_deleteblockfrommodule()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE RESOURCE FROM TOPIC ********************************************************/}

                {/**    MODAL: DELETE MODAL **************************************************************************/}

                <Modal id="mdl_deletemodule"
                       dimmer={'inverted'}
                       open={this.state.mdl_deletemodule_open}
                       onClose={() => { this.setState({ mdl_deletemodule_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Delete this module</div>
                    <div className="modal-content">
                        Do you really want to delete this module?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.setState({ mdl_deletemodule_open: false })}>NO</Button>
                            {!this.state.deletemodule_updating
                                ?   <Button className='activestate' onClick={() => this.onClick_deletemodule()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: DELETE MODAL **********************************************************************/}

            </div>
        );
    }
}