import React, { Component } from "react";
import "./learningpath-details.css";
import { API } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Dropdown, Table, Modal} from 'semantic-ui-react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../react-draft-wysiwyg.css';


export default class learningpath_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            learningpath: {},
            learningpathblock: {},
            learningpathblocks: [],
            blocksdd: [],
            blockorderdd: [],
            editorState: EditorState.createEmpty(),
            mdl_addblock_open: false,
            didmount: false,
            learningpath_updating: false,
            learningpath_updateready: false,
            learningpathblock_updating: false,
            learningpathblock_updateready: false,
            mdl_removeblockfromlearningpath_open: false,
            removeblockfromlearningpath_updating: false,
            removeblockfromlearningpath_updateready: false,
            mdl_deletelearningpath_updating: false,
            mdl_deletelearningpath_open: false,
            removeblock: '',
            removeblockid: ''
        };

    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populatelearningpath();
            this.populatelearningpathblocks();
            this.populateblocksdd();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populatelearningpath(){
        try {
            let learningpath = await this.API_get_learningpath();
            learningpath[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ learningpath: learningpath[0] });
            if(this.state.learningpath.description) {
                this.setState({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.state.learningpath.description).contentBlocks)) });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updatelearningpath(){
        try {
            await this.API_post_learningpath(this.state.learningpath);
            this.setState({ learningpath_updating: false });
            this.setState({ learningpath_updateready: false });
            this.populatelearningpath();
        } catch (e) {
            console.log(e);
        }
    }

    async populatelearningpathblocks(){
        try {
            let learningpathblocks = await this.API_get_learningpathblocks();
            this.setState({ learningpathblocks });

            let tempblockorderdd = [];
            for(let i = 1; i < learningpathblocks.length + 2; i++){
                tempblockorderdd.push({value:i,text:i})
            }
            this.setState({ blockorderdd: tempblockorderdd });

        } catch (e) {
            console.log(e);
        }
    }

    async insertlearningpathblock(){
        try {
            const { learningpathblock } = { ...this.state };
            const currentState = learningpathblock;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ learningpathblock: currentState });

            await this.API_put_learningpathblock(this.state.learningpathblock);
            this.setState({ learningpathblock_updating: false });
            this.setState({ learningpathblock_updateready: false });
            this.onClose_mdl_addblock();
            this.populatelearningpathblocks();
        } catch (e) {
            console.log(e);
        }
    }

    async onClick_updatelearningpathblock(){
        this.setState({ learningpathblock_updating: true });
        try {
            const { learningpathblock } = { ...this.state };
            const currentState = learningpathblock;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ learningpathblock: currentState });

            await this.API_post_learningpathblock(this.state.learningpathblock);
            this.setState({ learningpathblock_updating: false });
            this.setState({ learningpathblock_updateready: false });
            this.onClose_mdl_addblock();
            this.populatelearningpathblocks();
        } catch (e) {
            console.log(e);
        }
    }

    async populatelearningpathblock(block_id){
        let learningpathblock = await this.API_get_learningpathblock(block_id);
        learningpathblock[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ learningpathblock: learningpathblock[0] });
    }

    async populateblocksdd(){
        try {
            let blocksdd = await this.API_get_blocksdd();
            this.setState({ blocksdd });
        } catch (e) {
            console.log(e);
        }
    }


    API_get_learningpath() {
        return API.get('tap-admin', '/learningpaths/get-learningpath/'+this.props.match.params.learningpathid);
    }

    API_post_learningpath(body) {
        return API.post('tap-admin', '/learningpaths/post-learningpath/'+this.props.match.params.learningpathid, { body });
    }

    API_delete_learningpath() {
        return API.del('tap-admin', '/learningpaths/delete-learningpath/'+this.props.match.params.learningpathid);
    }

    API_get_learningpathblocks() {
        return API.get('tap-admin', '/learningpaths/get-learningpathblocks/'+this.props.match.params.learningpathid);
    }

    API_get_learningpathblock(block_id) {
        return API.get('tap-admin', '/learningpaths/get-learningpathblock/'+block_id);
    }

    API_del_learningpathblock() {
        return API.del('tap-admin', '/learningpaths/del-learningpathblock/'+this.state.removeblockid);
    }

    API_put_learningpathblock(body) {
        return API.put('tap-admin', '/learningpaths/put-learningpathblock/'+this.props.match.params.learningpathid, { body });
    }

    API_post_learningpathblock(body) {
        return API.post('tap-admin', '/learningpaths/post-learningpathblock/'+this.state.learningpathblock.id, { body });
    }

    API_get_blocksdd() {
        return API.get('tap-admin', '/blocks/get-blocksdd');
    }

    onClick_btn_detailparent(){
        this.props.history.push('/learningpaths');
    }

    onChange_editor = (editorState) => {
        this.setState({ editorState });
        const { learningpath } = { ...this.state };
        const currentState = learningpath;
        currentState['description'] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState({ learningpath: currentState });
        this.setState({ learningpath_updateready: true })
    };

    onChange_radio = (e, { name,value }) => {
        const { learningpath } = { ...this.state };
        const currentState = learningpath;
        currentState[name] = value;
        this.setState({ learningpath: currentState });
        this.setState({ learningpath_updateready: true })
    };

    onChange_input = (event) => {
        const { learningpath } = { ...this.state };
        const currentState = learningpath;
        currentState[event.target.name] = event.target.value;
        this.setState({ learningpath: currentState });
        this.setState({ learningpath_updateready: true })
    };

    onChange_dropdown = (e, { name,value }) => {
        const { learningpathblock } = { ...this.state };
        const currentState = learningpathblock;
        currentState[name] = value;
        this.setState({ learningpathblock: currentState });
        this.setState({ learningpathblock_updateready: true })
    };

    onClick_viewblock(block_id){
        this.props.history.push('/block-details/'+block_id);
    }

    onOpen_mdl_addblock(){
        const { learningpathblock } = { ...this.state };
        const currentState = learningpathblock;
        currentState['block_id'] = '';
        currentState['block_order'] = '';
        this.setState({ learningpathblock: currentState });

        this.setState({ mdl_addblock_mode: 'INSERT' })
        this.setState({ moduleblock_updateready: false });
        this.setState({ mdl_addblock_open: true });
    }

    onClick_editblock(block_id){
        this.setState({ mdl_addblock_mode: 'UPDATE' })
        this.setState({ moduleblock_updateready: false });
        this.setState({ mdl_addblock_open: true })
        this.populatelearningpathblock(block_id)
    }

    onClose_mdl_addblock(){
        this.setState({ mdl_addblock_open: false })
    }

    onClick_removeblockfromlearningpath(event, removeblockid, removeblock){
        event.stopPropagation();
        this.setState({ removeblock: removeblock });
        this.setState({ removeblockid: removeblockid });
        this.setState({ removeblockfromlearningpath_updateready: true })
        this.setState({ mdl_removeblockfromlearningpath_open: true })
    }

    async onClick_deleteblockfromlearningpath(){
        this.setState({ removeblockfromlearningpath_updating: true });
        try {
            await this.API_del_learningpathblock();
            this.setState({ removeblockfromlearningpath_updating: false });
            this.setState({ removeblockfromlearningpath_updateready: false });
            this.setState({ mdl_removeblockfromlearningpath_open: false })
            this.populatelearningpathblocks();
        } catch (e) {
            console.log(e);
        }
    }

    onClose_mdl_removeblockfromlearningpath(){
        this.setState({ mdl_removeblockfromlearningpath_open: false })
    }

    async onClick_updatelearningpath(){
        this.setState({ learningpath_updating: true });
        this.updatelearningpath();
    }

    async onClick_insertlearningpathblock(){
        this.setState({ learningpathblock_updating: true });
        this.insertlearningpathblock();
    }

    onClick_delete(){
        this.setState({mdl_deletelearningpath_updating: false});
        this.setState({mdl_deletelearningpath_open: true});
    }

    async onClick_deletelearningpath(){
        this.setState({mdl_deletelearningpath_updating: true})
        try {
            await this.API_delete_learningpath();
            this.setState({mdl_deletelearningpath_updating: false})
            this.setState({mdl_deletelearningpath_open: false});
            this.props.history.push('/learningpaths');
        } catch (e) {
            alert(e);
            this.setState({mdl_deletelearningpath_updating: false})
            this.setState({mdl_deletelearningpath_open: false});
        }
    }

    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        const { editorState } = this.state;
        return (
            <div className="content">
                <div className="content-topmenu" id="learningpath_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Learning Paths</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_blockdetails">
                        <div className="content-card-header">
                            {this.state.learningpath.learningpath_name}
                        </div>
                        {this.state.learningpath &&
                        <div className="resource content-card-content">
                            <Form>
                                <Label>LEARNING PATH STATUS</Label>
                                <Form.Group>
                                    <Form.Field
                                        control={Radio}
                                        label='Active'
                                        value='ACTIVE'
                                        name='status'
                                        checked={this.state.learningpath.status === 'ACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='Inactive'
                                        value='INACTIVE'
                                        name='status'
                                        checked={this.state.learningpath.status === 'INACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='learningpath'
                                        id='learningpath'
                                        name='learningpath_name'
                                        value={this.state.learningpath.learningpath_name || ''}
                                        control={Input}
                                        label='LEARNING PATH NAME'
                                        placeholder='Learning path name...'
                                        onChange={this.onChange_input}
                                    />
                                </Form.Group>
                                <div>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        toolbarClassName="demo-toolbar"
                                        onEditorStateChange={this.onChange_editor}
                                        defaultEditorState={this.state.learningpath.description}
                                        toolbar={{
                                            options: ['inline', 'history'],
                                            inline: {options: ['bold', 'italic', 'underline',]},
                                        }}
                                    />
                                </div>
                            </Form>
                        </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.learningpath.lastmodifiedby} - {this.state.learningpath.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button >CANCEL</Button>
                                {this.state.learningpathblocks.length === 0 &&
                                    <Button onClick={() => this.onClick_delete()}>DELETE</Button>
                                }
                                {!this.state.learningpath_updating
                                    ?
                                    <Button className={(this.state.learningpath_updateready ? 'activestate' : '')} onClick={() => this.onClick_updatelearningpath()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="content-card" id="cc_moduleblocks">
                        <div className="content-card-header">
                            Learning path blocks
                        </div>
                        <div className="resource content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_order">ORDER</Table.HeaderCell>
                                        <Table.HeaderCell>BLOCK</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.learningpathblocks.map((item,i) =>
                                        <Table.Row key={i}  onClick={() => this.onClick_editblock(item.id)}>
                                            <Table.Cell className="tbl_order">{item.block_order}</Table.Cell>
                                            <Table.Cell>{item.block_name}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={event => this.onClick_removeblockfromlearningpath(event, item.id, item.block_name)}><img className="table_statusicon" src="/icons/x (e22d30).svg" alt="delete item icon"></img></Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewblock(item.id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified"></div>
                            <div className="content-card-footer-buttons">
                                <Button onClick={() => this.onOpen_mdl_addblock()}>ADD A BLOCK</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/**    MODAL: ADD BLOCK *****************************************************************************/}

                <Modal className="mdl_addblock"
                       dimmer={'inverted'}
                       open={this.state.mdl_addblock_open}
                       onClose={() => { this.setState({ mdl_addblock_open: false }) }}
                >
                    {this.state.mdl_addblock_mode === 'INSERT'
                        ?
                        <div className="modal-header">Add a block to this learning path</div>
                        :
                        <div className="modal-header">Update this block</div>
                    }
                    <div className="modal-content">
                        <Form>
                            <Label>BLOCK ORDER</Label>
                            <Form.Group className="block_order">
                                <Dropdown
                                    id='block_order'
                                    placeholder='0'
                                    fluid
                                    selection
                                    value={this.state.learningpathblock.block_order}
                                    name='block_order'
                                    onChange={this.onChange_dropdown}
                                    options={this.state.blockorderdd}
                                />
                            </Form.Group>
                            <Label>BLOCKS</Label>
                            <Form.Group className="dd_blocks">
                                <Dropdown
                                    id='dd_blocks'
                                    placeholder='select a block'
                                    fluid
                                    selection
                                    value={this.state.learningpathblock.block_id}
                                    name='block_id'
                                    onChange={this.onChange_dropdown}
                                    options={this.state.blocksdd}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_addblock()}>CANCEL</Button>
                            {!this.state.learningpathblock_updating
                                ?   (   this.state.mdl_addblock_mode === 'INSERT'
                                        ?
                                        <Button className={(this.state.learningpathblock_updateready ? 'activestate' : '')} onClick={() => this.onClick_insertlearningpathblock()}>ADD BLOCK</Button>
                                        :
                                        <Button className={(this.state.learningpathblock_updateready ? 'activestate' : '')} onClick={() => this.onClick_updatelearningpathblock()}>UPDATE</Button>
                                )
                                :   (   this.state.mdl_addblock_mode === 'UPDATE'
                                        ?
                                        <Button loading className='activestate'>ADD BLOCK</Button>
                                        :
                                        <Button loading className='activestate'>UPDATE</Button>
                                )
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: ADD BLOCK *************************************************************************/}

                {/**    MODAL: REMOVE BLOCK FROM LEARNING PATH *******************************************************/}

                <Modal id="mdl_removeblockfromlearningpath"
                       dimmer={'inverted'}
                       open={this.state.mdl_removeblockfromlearningpath_open}
                       onClose={() => { this.setState({ mdl_removeblockfromlearningpath_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove block from learning path</div>
                    <div className="modal-content">
                        Do you really want to remove '{this.state.removeblock}' from the topic '{this.state.learningpath.learningpath_name}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removeblockfromlearningpath()}>NO</Button>
                            {!this.state.removeblockfromlearningpath_updating
                                ?   <Button className={(this.state.removeblockfromlearningpath_updateready ? 'activestate' : '')} onClick={() => this.onClick_deleteblockfromlearningpath()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE BLOCK FROM LEARNING PATH ***************************************************/}

                {/**    MODAL: DELETE LEARNING PATH ******************************************************************/}

                <Modal id="mdl_deletelearningpath"
                       dimmer={'inverted'}
                       open={this.state.mdl_deletelearningpath_open}
                       onClose={() => { this.setState({ mdl_deletelearningpath_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Delete this learning path</div>
                    <div className="modal-content">
                        Do you really want to delete this learning path?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.setState({ mdl_deletelearningpath_open: false })}>NO</Button>
                            {!this.state.deletelearningpath_updating
                                ?   <Button className='activestate' onClick={() => this.onClick_deletelearningpath()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: DELETE LEARNING PATH **************************************************************/}

            </div>
        );
    }
}