import React, { Component } from "react";
import "./demoaccounts.css";
import { API } from "aws-amplify";
import {Button, Form, Input, Label, Modal, Progress, Radio, Table} from 'semantic-ui-react'


export default class demoaccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            mdl_createdemoaccount_open: false,
            mdl_updatedemoaccount_open: false
        };
    }


    async componentDidMount() {
            // this.populatemembers();
    }

    onOpen_mdl_createdemoaccount(){
        this.setState({ mdl_updatedemoaccount_open: true });
    }



    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Demo Accounts</div>
                    <Button onClick={() => this.onOpen_mdl_createdemoaccount()}>NEW DEMO ACCOUNT</Button>
                </div>
                <div className="content-wrapper">
                    <div className="content-card">
                        <div className="content-card-header">
                            All Demo Accounts
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>FIRST NAME</Table.HeaderCell>
                                        <Table.HeaderCell>LAST NAME</Table.HeaderCell>
                                        <Table.HeaderCell>EMAIL</Table.HeaderCell>
                                        <Table.HeaderCell>CREATED</Table.HeaderCell>
                                        <Table.HeaderCell>EXPIRES</Table.HeaderCell>
                                        <Table.HeaderCell>CREATED BY</Table.HeaderCell>
                                        <Table.HeaderCell>STATUS</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
{/*
                                    {this.state.members.map((item,i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.firstname}</Table.Cell>
                                            <Table.Cell>{item.lastname}</Table.Cell>
                                            <Table.Cell>{item.email}</Table.Cell>
                                            <Table.Cell>{item.created}</Table.Cell>
                                        </Table.Row>)}
*/}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <Modal className="mdl_createdemoaccount"
                       dimmer={'inverted'}
                       open={this.state.mdl_createdemoaccount_open}
                       onClose={() => { this.setState({ mdl_createdemoaccount_open: false }) }}
                >
                    <div className="modal-header">Create A New Demo Account</div>
                    <div className="modal-content">
                        <Form>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='FIRST NAME'
                                    placeholder='First name...'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='LAST NAME'
                                    placeholder='Last name...'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='EXPIRY DATE'
                                    placeholder='DEC 31, 2020'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='EMAIL'
                                    placeholder='Email...'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    type='password'
                                    label='PASSWORD'
                                    placeholder='Password...'
                                />
                            </Form.Group>
                            <Form.Group>
                                <div className="passwordvalidation">
                                    <div className="passwordvalidation_item">
                                        {this.state.validatepassword_length ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark (3dbf1c).svg" alt="password status"></img></div>
                                            :
                                            <div className="passwordvalidation_item_icon"><img src="/icons/x (e22d30).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">8 characters long</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {this.state.validatepassword_uppercase ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark (3dbf1c).svg" alt="password status"></img></div>
                                            :
                                            <div className="passwordvalidation_item_icon"><img src="/icons/x (e22d30).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">upper case</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {this.state.validatepassword_lowercase ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark (3dbf1c).svg" alt="password status"></img></div>
                                            :
                                            <div className="passwordvalidation_item_icon"><img src="/icons/x (e22d30).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">lower case</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {this.state.validatepassword_number ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark (3dbf1c).svg" alt="password status"></img></div>
                                            :
                                            <div className="passwordvalidation_item_icon"><img src="/icons/x (e22d30).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">number</div>
                                    </div>
                                    <div className="passwordvalidation_item">
                                        {this.state.validatepassword_specialcharacter ?
                                            <div className="passwordvalidation_item_icon"><img src="/icons/checkmark (3dbf1c).svg" alt="password status"></img></div>
                                            :
                                            <div className="passwordvalidation_item_icon"><img src="/icons/x (e22d30).svg" alt="password status"></img></div>
                                        }
                                        <div className="passwordvalidation_item_text">special character</div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button >CANCEL</Button>
                            <Button className='activestate'>CREATE</Button>
                        </div>
                    </div>
                </Modal>


                <Modal className="mdl_updatedemoaccount"
                       dimmer={'inverted'}
                       open={this.state.mdl_updatedemoaccount_open}
                       onClose={() => { this.setState({ mdl_updatedemoaccount_open: false }) }}
                >
                    <div className="modal-header">Update Demo Account</div>
                    <div className="modal-content">
                        <Form>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='FIRST NAME'
                                    placeholder='First name...'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='LAST NAME'
                                    placeholder='Last name...'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='EXPIRY DATE'
                                    placeholder='DEC 31, 2020'
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='input'
                                    control={Input}
                                    label='EMAIL'
                                    placeholder='Email...'
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button >CANCEL</Button>
                            <Button >INACTIVATE</Button>
                            <Button className='activestate'>UPDATE</Button>
                        </div>
                    </div>
                </Modal>


            </div>
        );
    }
}