import React, { Component } from "react";
import "./blocks.css";
import { API } from "aws-amplify";
import {Button, Form, Input, Label, Modal, Radio, Table} from 'semantic-ui-react'


export default class blocks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blocks: [],
            didmount: false,
            newblock: {},
            mdl_createblock_open: false,
            newblock_updating: false,
            newblock_updateready: false,
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateblocks();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populateblocks(){
        try {
            let blocks = await this.API_get_blocks();
            this.setState({ blocks });
        } catch (e) {
            console.log(e);
        }
    }


    async insertblock(){
        try {
            let result_put_block = await this.API_put_block(this.state.newblock);
            this.setState({ newblock_updating: false });
            this.setState({ newblock_updateready: false });
            this.onClose_mdl_createblock();
            this.props.history.push('/block-details/'+result_put_block['insertId']);
        } catch (e) {
            console.log(e);
        }
    }


    API_get_blocks() {
        return API.get('tap-admin', '/blocks/get-blocks');
    }

    API_put_block(body) {
        return API.put('tap-admin', '/blocks/put-block/', { body });
    }

    tableRowClickFunc(detailid) {
        this.props.history.push('/block-details/'+detailid);
    }

    onOpen_mdl_createblock(){
        const { newblock } = { ...this.state };
        const currentState = newblock;
        currentState['status'] = 'ACTIVE';
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newblock: currentState });
        this.setState({ newblock_updateready: false });
        this.setState({ mdl_createblock_open: true });
    }

    onClose_mdl_createblock(){
        this.setState({ mdl_createblock_open: false })
    }

    onChange_mdl_createblock_input = (event) => {
        const { newblock } = { ...this.state };
        const currentState = newblock;
        currentState[event.target.name] = event.target.value;
        this.setState({ newblock: currentState });
        this.setState({ newblock_updateready: true })
    };

    onChange_mdl_createblock_radio = (e, { name,value }) => {
        const { newblock } = { ...this.state };
        const currentState = newblock;
        currentState[name] = value;
        this.setState({ newblock: currentState });
        this.setState({ newblock_updateready: true })
    };

    async onClick_createblock(){
        this.setState({ newblock_updating: true });
        this.insertblock();
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }

    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Blocks</div>
                    <Button onClick={() => this.onOpen_mdl_createblock()}>NEW BLOCK</Button>
                </div>
                <div className="content-wrapper">
                    <div className="content-card">
                        <div className="content-card-header">
                            Blocks
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                        <Table.HeaderCell>IMAGE</Table.HeaderCell>
                                        <Table.HeaderCell>BLOCK NAME</Table.HeaderCell>
                                        <Table.HeaderCell>PAGE CONTENT</Table.HeaderCell>
                                        <Table.HeaderCell>DEFAULT RESOURCE</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>MODULES</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>PATHS</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>TOPICS</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>VIEWS</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.blocks.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.id)}>
                                            <Table.Cell className="tbl_blockstatus">{item.status === 'ACTIVE' && <img className="table_statusicon" src="/icons/checkmark (3dbf1c).svg" alt="checkmark icon"></img>}</Table.Cell>
                                            <Table.Cell className="tbl_blockimage"><img src={"https://tap-app-resources.s3.amazonaws.com/public/jpg/block_"+item.id+".jpg"}
                                                                                        onError={this.addDefaultSrc}
                                                                                        alt="block header"></img></Table.Cell>
                                            <Table.Cell>{item.block_name}</Table.Cell>
                                            <Table.Cell>{item.content && item.content.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell>{item.defaultresource}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.modules}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.learningpaths}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.topics}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.views}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <Modal className="mdl_createblock"
                       dimmer={'inverted'}
                       open={this.state.mdl_createblock_open}
                       onClose={() => { this.setState({ mdl_createblock_open: false }) }}
                >
                    <div className="modal-header">Create A New Block</div>
                    <div className="modal-content">
                        <Form onSubmit={this.onSubmit_mdl_createtopic}
                        >
                            <Label>BLOCK STATUS</Label>
                            <Form.Group>
                                <Form.Field
                                    control={Radio}
                                    label='Active'
                                    value='ACTIVE'
                                    name='status'
                                    checked={this.state.newblock.status === 'ACTIVE'}
                                    onChange={this.onChange_mdl_createblock_radio}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Inactive'
                                    value='INACTIVE'
                                    name='status'
                                    checked={this.state.newblock.status === 'INACTIVE'}
                                    onChange={this.onChange_mdl_createblock_radio}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='block'
                                    id='block'
                                    name='block'
                                    value={this.state.newblock.block}
                                    control={Input}
                                    label='BLOCK NAME'
                                    placeholder='Block name...'
                                    onChange={this.onChange_mdl_createblock_input}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_createblock()}>CANCEL</Button>
                            {!this.state.newblock_updating
                                ?
                                <Button className={(this.state.newblock_updateready ? 'activestate' : '')} onClick={() => this.onClick_createblock()}>CREATE</Button>
                                :
                                <Button loading className='activestate'>CREATE</Button>
                            }
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}