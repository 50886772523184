import React, { Component } from "react";
import "./landingpage-details.css";
import { API } from "aws-amplify";
import {Button, Label, Form, Dropdown, Table, Modal} from 'semantic-ui-react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../react-draft-wysiwyg.css';


export default class landingpage_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            landingpage: {},
            landingpagemodule: {},
            landingpagemodules: [],
            modulesdd: [],
            editorState: EditorState.createEmpty(),
            mdl_addmodule_open: false,
            didmount: false,
            landingpage_updating: false,
            landingpage_updateready: false,
            landingpagemodule_updating: false,
            landingpagemodule_updateready: false,
            mdl_removemodulefromlandingpage_open: false,
            removemodulefromlandingpage_updating: false,
            removemodulefromlandingpage_updateready: false,
            removemodule: '',
            removemoduleid: ''
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populatelandingpage();
            this.populatelandingpagemodules();
            this.populatemodulesdd();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populatelandingpage(){
        try {
            let landingpage = await this.API_get_landingpage();
            landingpage[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ landingpage: landingpage[0] });
            if(this.state.landingpage.description) {
                this.setState({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.state.landingpage.description).contentBlocks)) });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updatelandingpage(){
        try {
            await this.API_post_landingpage(this.state.landingpage);
            this.setState({ landingpage_updating: false });
            this.setState({ landingpage_updateready: false });
            this.populatelandingpage();
        } catch (e) {
            console.log(e);
        }
    }

    async populatelandingpagemodules(){
        try {
            let landingpagemodules = await this.API_get_landingpagemodules();
            this.setState({ landingpagemodules });
        } catch (e) {
            console.log(e);
        }
    }

    async insertlandingpagemodule(){
        try {
            const { landingpagemodule } = { ...this.state };
            const currentState = landingpagemodule;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ landingpagemodule: currentState });

            await this.API_put_landingpagemodule(this.state.landingpagemodule);
            this.setState({ landingpagemodule_updating: false });
            this.setState({ landingpagemodule_updateready: false });
            this.onClose_mdl_addmodule();
            this.populatelandingpagemodules();
        } catch (e) {
            console.log(e);
        }
    }

    async populatemodulesdd(){
        try {
            let modulesdd = await this.API_get_modulesdd();
            this.setState({ modulesdd });
        } catch (e) {
            console.log(e);
        }
    }


    API_get_landingpage() {
        return API.get('tap-admin', '/landingpages/get-landingpage/'+this.props.match.params.landingpageid);
    }

    API_post_landingpage(body) {
        return API.post('tap-admin', '/landingpages/post-landingpage/'+this.props.match.params.landingpageid, { body });
    }

    API_get_landingpagemodules() {
        return API.get('tap-admin', '/landingpages/get-landingpagemodules/'+this.props.match.params.landingpageid);
    }

    API_put_landingpagemodule(body) {
        return API.put('tap-admin', '/landingpages/put-landingpagemodule/'+this.props.match.params.landingpageid, { body });
    }

    API_del_landingpagemodule() {
        return API.del('tap-admin', '/landingpages/del-landingpagemodule/'+this.state.removemoduleid);
    }

    API_get_modulesdd() {
        return API.get('tap-admin', '/modules/get-modulesdd');
    }

    onClick_btn_detailparent(){
        this.props.history.push('/landingpages');
    }

    onChange_editor = (editorState) => {
        this.setState({ editorState });
        const { landingpage } = { ...this.state };
        const currentState = landingpage;
        currentState['description'] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState({ landingpage: currentState });
        this.setState({ landingpage_updateready: true })
    };

    onChange_dropdown = (e, { name,value }) => {
        const { landingpagemodule } = { ...this.state };
        const currentState = landingpagemodule;
        currentState[name] = value;
        this.setState({ landingpagemodule: currentState });
        this.setState({ landingpagemodule_updateready: true })
    };

    onClick_viewmodule(module_id){
        this.props.history.push('/module-details/'+module_id);
    }

    onOpen_mdl_addmodule(){
        this.setState({ landingpagemodule_updateready: false });
        this.setState({ mdl_addmodule_open: true });
    }

    onClose_mdl_addmodule(){
        this.setState({ mdl_addmodule_open: false })
    }

    onClick_removemodulefromlandingpage(event, removemoduleid, removemodule) {
        event.stopPropagation();
        this.setState({ removemodule: removemodule });
        this.setState({ removemoduleid: removemoduleid });
        this.setState({ removemodulefromlandingpage_updateready: true })
        this.setState({ mdl_removemodulefromlandingpage_open: true })
    }

    async onClick_deletemodulefromlandingpage(){
        this.setState({ removemodulefromlandingpage_updating: true });
        try {
            await this.API_del_landingpagemodule();
            this.setState({ removemodulefromlandingpage_updating: false });
            this.setState({ removemodulefromlandingpage_updateready: false });
            this.setState({ mdl_removemodulefromlandingpage_open: false })
            this.populatelandingpagemodules();
        } catch (e) {
            console.log(e);
        }
    }


    onClose_mdl_removemodulefromlandingpage(){
        this.setState({ mdl_removemodulefromlandingpage_open: false })
    }

    handleChange_modules = (event, {value}) => {
        console.log(value);
        let bird_name = event.target.textContent;
        console.log(bird_name);
    };

    async onClick_updatelandingpage(){
        this.setState({ landingpage_updating: true });
        this.updatelandingpage();
    }

    async onClick_insertlandingpagemodule(){
        this.setState({ landingpagemodule_updating: true });
        this.insertlandingpagemodule();
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        const { editorState } = this.state;
        return (
            <div className="content">
                <div className="content-topmenu" id="landingpage_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Landing Pages</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_blockdetails">
                        <div className="content-card-header">
                            {this.state.landingpage.landingpage_name}
                        </div>
                        {this.state.landingpage &&
                        <div className="resource content-card-content">
                            <Form>
                                <div>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        toolbarClassName="demo-toolbar"
                                        onEditorStateChange={this.onChange_editor}
                                        defaultEditorState={this.state.landingpage.description}
                                        toolbar={{
                                            options: ['inline', 'history'],
                                            inline: {options: ['bold', 'italic', 'underline',]},
                                        }}
                                    />
                                </div>
                            </Form>
                        </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.landingpage.lastmodifiedby} - {this.state.landingpage.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button >CANCEL</Button>
                                {!this.state.landingpage_updating
                                    ?
                                    <Button className={(this.state.landingpage_updateready ? 'activestate' : '')} onClick={() => this.onClick_updatelandingpage()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="content-card" id="cc_moduleblocks">
                        <div className="content-card-header">
                            Landing Page Modules
                        </div>
                        <div className="resource content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>MODULE</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.landingpagemodules.map((item,i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.module_name}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={event => this.onClick_removemodulefromlandingpage(event, item.id, item.module_name)}><img className="table_statusicon" src="/icons/x (e22d30).svg" alt="delete item icon"></img></Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewmodule(item.id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified"></div>
                            <div className="content-card-footer-buttons">
                                <Button onClick={() => this.onOpen_mdl_addmodule()}>ADD A MODULE</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal className="mdl_addmodule"
                       dimmer={'inverted'}
                       open={this.state.mdl_addmodule_open}
                       onClose={() => { this.setState({ mdl_addmodule: false }) }}
                >
                    <div className="modal-header">Add A Module</div>
                    <div className="modal-content">
                        <Form>
                            <Label>MODULES</Label>
                            <Form.Group className="dd_modules">
                                <Dropdown
                                    id='dd_module'
                                    placeholder='select a module...'
                                    fluid
                                    selection
                                    name='module_id'
                                    onChange={this.onChange_dropdown}
                                    options={this.state.modulesdd}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_addmodule()}>CANCEL</Button>
                            {!this.state.landingpagemodule_updating
                                ?
                                <Button className={(this.state.landingpagemodule_updateready ? 'activestate' : '')} onClick={() => this.onClick_insertlandingpagemodule()}>ADD MODULE</Button>
                                :
                                <Button loading className='activestate'>ADD MODULE</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    MODAL: REMOVE MODULE FROM LANDING PAGE *******************************************************/}

                <Modal id="mdl_removemodulefromlandingpage"
                       dimmer={'inverted'}
                       open={this.state.mdl_removemodulefromlandingpage_open}
                       onClose={() => { this.setState({ mdl_removemodulefromlandingpage_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove module from landing page</div>
                    <div className="modal-content">
                        Do you really want to remove '{this.state.removemodule}' from the landing page '{this.state.landingpage.landingpage_name}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removemodulefromlandingpage()}>NO</Button>
                            {!this.state.removemodulefromlandingpage_updating
                                ?   <Button className={(this.state.removemodulefromlandingpage_updateready ? 'activestate' : '')} onClick={() => this.onClick_deletemodulefromlandingpage()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE MODULE FROM LANDING PAGE ***************************************************/}

            </div>
        );
    }
}