import React, { Component } from "react";
import { Form, Input, Button } from 'semantic-ui-react'
import { Auth, API } from "aws-amplify";
import "./login.css";


export default class login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user_loading: false,
            email: "",
            password: ""
        };

        this.cognitodata = {}

    }



    //  login input field change handler
    onChange_login = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };



    //  login form submit handler
    onSubmit_login = async event => {
        event.preventDefault();
        this.setState({ user_loading: true })
        this.login()
    };


    async login(){
        try {
            let cognitodata= await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
            this.populateuser(cognitodata.username)
        } catch (e) {
            console.log(e);
        }
    }

    async populateuser(username){
        try {
            let user = await this.API_get_user(username);
            console.log(user[0])
            this.props.userHasDetails(user[0])
            this.props.userHasAuthenticated(true);
            localStorage.setItem('cognitoid', this.cognitodata.username);
            this.props.history.push('/resources/')
        } catch (e) {
            console.log(e);
        }
    }

    //  API call to get-user
    API_get_user(username) {
        return API.get("tap-admin", "/users/get-user/"+username);
    }





    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="login_wrapper">
                <div className="login">
                    <div className="login_logo">
                        <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img>
                    </div>
                    <div className="login_form">
                        <Form onSubmit={this.onSubmit_login}>
                            <Form.Field id="email"
                                        value={this.state.email}
                                        onChange={this.onChange_login}
                                        control={Input}
                                        label="Email"
                                        placeholder="Email"
                            />
                            <Form.Field id="password"
                                        value={this.state.password}
                                        onChange={this.onChange_login}
                                        control={Input}
                                        type="password"
                                        label="Password"
                                        placeholder="Password"
                            />
                            {!this.state.user_loading
                                ?
                                    <Button primary
                                            id="btn_login_submit"
                                            type='submit'
                                    >Login</Button>
                                :
                                    <Button loading
                                            id="btn_login_submit"
                                    >Login</Button>
                            }
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}