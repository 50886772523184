import React, { Component } from "react";
import "./topic-details.css";
import { API } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Dropdown, Table, Modal} from 'semantic-ui-react'


export default class topic_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            topic: {},
            topicblocks: [],
            pdfsdd: [],
            videosdd: [],
            audiosdd: [],
            topic_updating: false,
            topic_updateready: false,
            mdl_removepdffromtopic_open: false,
            mdl_removedownloadpdffromtopic_open: false,
            mdl_removemp4fromtopic_open: false,
            removepdffromtopic_updating: false,
            removedownloadpdffromtopic_updating: false,
            removemp4fromtopic_updating: false,
            removepdffromtopic_updateready: false,
            removedownloadpdffromtopic_updateready: false,
            removemp4fromtopic_updateready: false,
            mdl_deletetopic_updating: false,
            mdl_deletetopic_open: false,
            user: JSON.parse(localStorage.getItem('user'))
        };


    }


    componentDidMount() {
        this.populatetopic();
        this.populatetopicblocks();
        this.populatepdfsdd();
        this.populatevideosdd();
        this.populateaudiosdd();
    }


    async populatetopic(){
        try {
            let topic = await this.API_get_topic();
            topic[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            if(!topic[0]['audio_id'] && !topic[0]['pdf_id'] && !topic[0]['video_id']){
                topic[0]['enabledelete'] = true;
            }else{
                topic[0]['enabledelete'] = false;
            }
            this.setState({ topic: topic[0] });
        } catch (e) {
            console.log(e);
        }
    }

    async updatetopic(){
        try {
            await this.API_post_topic(this.state.topic);
            this.setState({ topic_updating: false });
            this.setState({ topic_updateready: false });
            this.populatetopic();
        } catch (e) {
            console.log(e);
        }
    }

    async populatetopicblocks(){
        try {
            let topicblocks = await this.API_get_topicblocks();
            this.setState({ topicblocks });
        } catch (e) {
            console.log(e);
        }
    }

    async populatepdfsdd(){
        try {
            let pdfsdd = await this.API_get_pdfsdd();
            this.setState({ pdfsdd });
        } catch (e) {
            console.log(e);
        }
    }

    async populatevideosdd(){
        try {
            let videosdd = await this.API_get_videosdd();
            this.setState({ videosdd });
        } catch (e) {
            console.log(e);
        }
    }

    async populateaudiosdd(){
        try {
            let audiosdd = await this.API_get_audiosdd();
            this.setState({ audiosdd });
        } catch (e) {
            console.log(e);
        }
    }

    API_get_topic() {
        return API.get('tap-admin', '/topics/get-topic/'+this.props.match.params.topicid);
    }

    API_post_topic(body) {
        return API.post('tap-admin', '/topics/post-topic/'+this.props.match.params.topicid, { body });
    }

    API_delete_topic() {
        return API.del('tap-admin', '/topics/delete-topic/'+this.props.match.params.topicid);
    }

    API_post_pdftopic(body) {
        return API.post('tap-admin', '/topics/post-pdftopic/'+this.props.match.params.topicid, { body });
    }

    API_post_mp4topic(body) {
        return API.post('tap-admin', '/topics/post-mp4topic/'+this.props.match.params.topicid, { body });
    }

    API_get_topicblocks() {
        return API.get('tap-admin', '/topics/get-topicblocks/'+this.props.match.params.topicid);
    }

    API_get_pdfsdd() {
        return API.get('tap-admin', '/resources/get-pdfsdd');
    }

    API_get_videosdd() {
        return API.get('tap-admin', '/resources/get-videosdd');
    }

    API_get_audiosdd() {
        return API.get('tap-admin', '/resources/get-audiosdd');
    }

    onClick_btn_detailparent(){
        this.props.history.push('/topics');
    }

    onChange_radio = (e, { name,value }) => {
        const { topic } = { ...this.state };
        const currentState = topic;
        currentState[name] = value;
        this.setState({ topic: currentState });
        this.setState({ topic_updateready: true })
    };

    onChange_input = (event) => {
        const { topic } = { ...this.state };
        const currentState = topic;
        currentState[event.target.name] = event.target.value;
        this.setState({ topic: currentState });
        this.setState({ topic_updateready: true })
    };

    onChange_dropdown = (e, { name,value }) => {
        const { topic } = { ...this.state };
        const currentState = topic;
        currentState[name] = value;
        this.setState({ topic: currentState });
        this.setState({ topic_updateready: true })
    };

    onClick_viewresource(resource_id){
       this.props.history.push('/resource-details/'+resource_id);
    }

    onClick_viewblock(block_id){
        this.props.history.push('/block-details/'+block_id);
    }

    onClick_removepdffromtopic(topic_id){
        this.setState({ removepdffromtopic_updateready: true })
        this.setState({ mdl_removepdffromtopic_open: true })
    }

    onClick_removedownloadpdffromtopic(topic_id){
        this.setState({ removedownloadpdffromtopic_updateready: true })
        this.setState({ mdl_removedownloadpdffromtopic_open: true })
    }

    onClick_removemp4fromtopic(video_id){
        this.setState({ removemp4fromtopic_updateready: true })
        this.setState({ mdl_removemp4fromtopic_open: true })
    }

    async onClick_deletepdffromtopic(){
        this.setState({ removepdffromtopic_updating: true });
        try {
            await this.API_post_pdftopic(this.state.topic);
            this.setState({ removepdffromtopic_updating: false });
            this.setState({ removepdffromtopic_updateready: false });
            this.setState({ mdl_removepdffromtopic_open: false })
            this.populatetopic();
        } catch (e) {
            console.log(e);
        }
    }

    async onClick_deletedownloadpdffromtopic(){
        this.setState({ removedownloadpdffromtopic_updating: true });
        try {
            await this.API_post_pdftopic(this.state.topic);
            this.setState({ removedownloadpdffromtopic_updating: false });
            this.setState({ removedownloadpdffromtopic_updateready: false });
            this.setState({ mdl_removedownloadpdffromtopic_open: false })
            this.populatetopic();
        } catch (e) {
            console.log(e);
        }
    }

    async onClick_deletemp4fromtopic(){
        this.setState({ removemp4fromtopic_updating: true });
        try {
            await this.API_post_mp4topic(this.state.topic);
            this.setState({ removemp4fromtopic_updating: false });
            this.setState({ removemp4fromtopic_updateready: false });
            this.setState({ mdl_removemp4fromtopic_open: false })
            this.populatetopic();
        } catch (e) {
            console.log(e);
        }
    }

    onClose_mdl_removepdffromtopic(){
        this.setState({ mdl_removepdffromtopic_open: false })
    }

    onClose_mdl_removedownloadpdffromtopic(){
        this.setState({ mdl_removedownloadpdffromtopic_open: false })
    }

    onClose_mdl_removemp4fromtopic(){
        this.setState({ mdl_removemp4fromtopic_open: false })
    }

    async onClick_updatetopic(){
        this.setState({ topic_updating: true });
        this.updatetopic();
    }

    onClick_canceltopic(){
        this.props.history.push('/topics');
    }

    onClick_delete(){
        this.setState({mdl_deletetopic_updating: false});
        this.setState({mdl_deletetopic_open: true});
    }
    
    async onClick_deletetopic(){
        this.setState({mdl_deletetopic_updating: true})
        try {
            await this.API_delete_topic();
            this.setState({mdl_deletetopic_updating: false})
            this.setState({mdl_deletetopic_open: false});
            this.props.history.push('/topics');
        } catch (e) {
            alert(e);
            this.setState({mdl_deletetopic_updating: false})
            this.setState({mdl_deletetopic_open: false});
        }
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu" id="topic_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Topics</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_topicdetails">
                        <div className="content-card-header">
                            {this.state.topic.topic}
                        </div>
                        {this.state.topic &&
                            <div className="resource content-card-content">
                                <Form onSubmit={this.handleSubmitForm}
                                >
                                    <Label>TOPIC STATUS</Label>
                                    <Form.Group>
                                        <Form.Field
                                            control={Radio}
                                            label='Active'
                                            value='ACTIVE'
                                            name='status'
                                            checked={this.state.topic.status === 'ACTIVE'}
                                            onChange={this.onChange_radio}
                                        />
                                        <Form.Field
                                            control={Radio}
                                            label='Inactive'
                                            value='INACTIVE'
                                            name='status'
                                            checked={this.state.topic.status === 'INACTIVE'}
                                            onChange={this.onChange_radio}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Field
                                            className='topic'
                                            id='topic'
                                            name='topic'
                                            value={this.state.topic.topic || ''}
                                            control={Input}
                                            label='TOPIC NAME'
                                            placeholder='Topic name...'
                                            onChange={this.onChange_input}
                                        />
                                    </Form.Group>
                                    <Label>PDF TYPE</Label>
                                    <Form.Group>
                                        <Form.Field
                                            control={Radio}
                                            label='Normal'
                                            value=''
                                            name='resourcetype'
                                            checked={this.state.topic.resourcetype === ''}
                                            onChange={this.onChange_radio}
                                        />
                                        <Form.Field
                                            control={Radio}
                                            label='Cheat Sheet'
                                            value='CS'
                                            name='resourcetype'
                                            checked={this.state.topic.resourcetype === 'CS'}
                                            onChange={this.onChange_radio}
                                        />
                                        <Form.Field
                                            control={Radio}
                                            label='Action Sheet'
                                            value='AS'
                                            name='resourcetype'
                                            checked={this.state.topic.resourcetype === 'AS'}
                                            onChange={this.onChange_radio}
                                        />
                                    </Form.Group>
                                    <Label>RESOURCE - PDF</Label>
                                    <Form.Group className="dd_pdfs">
                                        <Dropdown
                                            placeholder='no PDF selected'
                                            fluid
                                            selection
                                            name='pdf_id'
                                            value={this.state.topic.pdf_id}
                                            text={this.state.topic.pdf}
                                            options={this.state.pdfsdd}
                                            onChange={this.onChange_dropdown}
                                        />
                                        {this.state.topic.pdf_id &&
                                            <div className="btn_deletedditem" onClick={() => this.onClick_removepdffromtopic(this.state.topic.pdf_id)}><img src="/icons/x (e22d30).svg" alt="delete item icon"></img></div>
                                        }
                                        {this.state.topic.pdf_id &&
                                            <div className="btn_viewdditem" onClick={() => this.onClick_viewresource(this.state.topic.pdf_id)}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                                        }
                                    </Form.Group>
                                    { (this.state.topic.resourcetype === 'CS' || this.state.topic.resourcetype === 'AS') &&
                                    <>
                                        <Label>RESOURCE - DOWNLOAD PDF (ACTION SHEET / CHEAT SHEET)</Label>
                                        <Form.Group className="dd_pdfs">
                                            <Dropdown
                                                placeholder='no donwload PDF selected'
                                                fluid
                                                selection
                                                name='downloadpdf_id'
                                                value={this.state.topic.downloadpdf_id}
                                                text={this.state.topic.downloadpdf}
                                                options={this.state.pdfsdd}
                                                onChange={this.onChange_dropdown}
                                            />
                                            {this.state.topic.downloadpdf_id &&
                                            <div className="btn_deletedditem" onClick={() => this.onClick_removedownloadpdffromtopic(this.state.topic.downloadpdf_id)}><img src="/icons/x (e22d30).svg" alt="delete item icon"></img></div>
                                            }
                                            {this.state.topic.downloadpdf_id &&
                                            <div className="btn_viewdditem" onClick={() => this.onClick_viewresource(this.state.topic.downloadpdf_id)}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                                            }
                                        </Form.Group>
                                    </>
                                    }
                                    { (this.state.topic.resourcetype !== 'CS' && this.state.topic.resourcetype !== 'AS') &&
                                    <>
                                        <Label>RESOURCE - VIDEO</Label>
                                        <Form.Group className="dd_pdfs">
                                            <Dropdown
                                                placeholder='no video file selected'
                                                fluid
                                                selection
                                                name='video_id'
                                                value={this.state.topic.video_id}
                                                text={this.state.topic.video}
                                                options={this.state.videosdd}
                                                onChange={this.onChange_dropdown}
                                            />
                                            {this.state.topic.video_id &&
                                            <div className="btn_deletedditem" onClick={() => this.onClick_removemp4fromtopic(this.state.topic.video_id)}><img src="/icons/x (e22d30).svg" alt="delete item icon"></img></div>
                                            }
                                            {this.state.topic.video_id &&
                                                <div className="btn_viewdditem" onClick={() => this.onClick_viewresource(this.state.topic.video_id)}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                                            }
                                        </Form.Group>
                                    </>
                                    }
                                    <Label>RESOURCE - AUDIO</Label>
                                    <Form.Group className="dd_pdfs">
                                        <Dropdown
                                            placeholder='no audio file selected'
                                            fluid
                                            selection
                                            name='audio_id'
                                            value={this.state.topic.audio_id}
                                            text={this.state.topic.audio}
                                            options={this.state.audiosdd}
                                            onChange={this.onChange_dropdown}
                                        />
                                        {this.state.topic.audio_id &&
                                            <div className="btn_deletedditem"><img src="/icons/x (e22d30).svg" alt="delete item icon"></img></div>
                                        }
                                        {this.state.topic.audio_id &&
                                            <div className="btn_viewdditem" onClick={() => this.onClick_viewresource(this.state.topic.audio_id)}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                                        }
                                    </Form.Group>
                                </Form>
                            </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.topic.lastmodifiedby} - {this.state.topic.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button onClick={() => this.onClick_canceltopic()}>CANCEL</Button>
                                {this.state.topic.enabledelete &&
                                    <Button onClick={() => this.onClick_delete()}>DELETE</Button>
                                }
                                {!this.state.topic_updating
                                    ?
                                    <Button className={(this.state.topic_updateready ? 'activestate' : '')} onClick={() => this.onClick_updatetopic()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="content-card" id="cc_topicdetails">
                        <div className="content-card-header">
                            Topic Assignment
                        </div>
                        <div className="resource content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>BLOCK</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.topicblocks.map((item,i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.block_name}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewblock(item.id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                {/**    MODAL: REMOVE RESOURCE FROM TOPIC ************************************************************/}

                <Modal id="mdl_removeresourcefromtopic"
                       dimmer={'inverted'}
                       open={this.state.mdl_removepdffromtopic_open}
                       onClose={() => { this.setState({ mdl_removepdffromtopic_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove pdf from topic</div>
                    <div className="modal-content">
                        Do you really want to remove '{this.state.topic.topic}' from the topic '{this.state.topic.topic}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removepdffromtopic()}>NO</Button>
                            {!this.state.removepdffromtopic_updating
                                ?   <Button className={(this.state.removepdffromtopic_updateready ? 'activestate' : '')} onClick={() => this.onClick_deletepdffromtopic()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE DOWNLOAD PDF FROM TOPIC ****************************************************/}

                <Modal id="mdl_removedownloadpdffromtopic"
                       dimmer={'inverted'}
                       open={this.state.mdl_removedownloadpdffromtopic_open}
                       onClose={() => { this.setState({ mdl_removedownloadpdffromtopic_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove download pdf from topic</div>
                    <div className="modal-content">
                        Do you really want to remove this download pdf from the topic '{this.state.topic.topic}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removedownloadpdffromtopic()}>NO</Button>
                            {!this.state.removedownloadpdffromtopic_updating
                                ?   <Button className={(this.state.removedownloadpdffromtopic_updateready ? 'activestate' : '')} onClick={() => this.onClick_deletedownloadpdffromtopic()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE RESOURCE FROM TOPIC ********************************************************/}

                {/**    MODAL: REMOVE MP4 FROM TOPIC *****************************************************************/}

                <Modal id="mdl_removemp4fromtopic"
                       dimmer={'inverted'}
                       open={this.state.mdl_removemp4fromtopic_open}
                       onClose={() => { this.setState({ mdl_removemp4fromtopic_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove mp4 from topic</div>
                    <div className="modal-content">
                        Do you really want to remove this mp4 from the topic '{this.state.topic.topic}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removemp4fromtopic()}>NO</Button>
                            {!this.state.removemp4fromtopic_updating
                                ?   <Button className={(this.state.removemp4fromtopic_updateready ? 'activestate' : '')} onClick={() => this.onClick_deletemp4fromtopic()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE RESOURCE FROM TOPIC ********************************************************/}

                {/**    MODAL: DELETE TOPIC ***********************************************************************/}

                <Modal id="mdl_deletetopic"
                       dimmer={'inverted'}
                       open={this.state.mdl_deletetopic_open}
                       onClose={() => { this.setState({ mdl_deletetopic_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Delete this topic</div>
                    <div className="modal-content">
                        Do you really want to delete this topic?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.setState({ mdl_deletetopic_open: false })}>NO</Button>
                            {!this.state.deletetopic_updating
                                ?   <Button className='activestate' onClick={() => this.onClick_deletetopic()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: DELETE RESOURCE *******************************************************************/}

            </div>
        );
    }
}