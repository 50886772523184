export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "tap-app-resources"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://api.tapadvance.net"
    },
    apiGateway_owlishly: {
        REGION: "us-east-1",
        URL: "https://asuhv8eo37.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_vqhfIBrMg",
        APP_CLIENT_ID: "5ulkqgd1blr90j727s0kf7cjke",
        IDENTITY_POOL_ID: "us-east-1:66e1239b-6c22-4fee-8891-33c6988d9a8f"
    },
};