import React, { Component } from "react";
import "./learningpaths.css";
import { API } from "aws-amplify";
import {Button, Form, Input, Label, Modal, Radio, Table} from 'semantic-ui-react'


export default class learningpaths extends Component {

    constructor(props) {
        super(props);

        this.state = {
            learningpaths: [],
            didmount: false,
            newlearningpath: {},
            mdl_createlearningpath_open: false,
            newlearningpath_updating: false,
            newlearningpath_updateready: false
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populatelearningpaths();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populatelearningpaths(){
        try {
            let learningpaths = await this.API_get_learningpaths();
            this.setState({ learningpaths });
        } catch (e) {
            console.log(e);
        }
    }

    async insertlearningpath(){
        try {
            let result_put_learningpath = await this.API_put_learningpath(this.state.newlearningpath);
            this.setState({ newlearningpath_updating: false });
            this.setState({ newlearningpath_updateready: false });
            this.onClose_mdl_createlearningpath();
            this.props.history.push('/learningpath-details/'+result_put_learningpath['insertId']);
        } catch (e) {
            console.log(e);
        }
    }


    API_get_learningpaths() {
        return API.get('tap-admin', '/learningpaths/get-learningpaths');
    }

    API_put_learningpath(body) {
        return API.put('tap-admin', '/learningpaths/put-learningpath/', { body });
    }

    tableRowClickFunc(detailid) {
        this.props.history.push('/learningpath-details/'+detailid);
    }

    onOpen_mdl_createlearningpath(){
        const { newlearningpath } = { ...this.state };
        const currentState = newlearningpath;
        currentState['status'] = 'ACTIVE';
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newlearningpath: currentState });
        this.setState({ newlearningpath_updateready: false });
        this.setState({ mdl_createlearningpath_open: true });
    }

    onClose_mdl_createlearningpath(){
        this.setState({ mdl_createlearningpath_open: false })
    }

    onChange_mdl_createlearningpath_input = (event) => {
        const { newlearningpath } = { ...this.state };
        const currentState = newlearningpath;
        currentState[event.target.name] = event.target.value;
        this.setState({ newlearningpath: currentState });
        this.setState({ newlearningpath_updateready: true })
    };

    onChange_mdl_createlearningpath_radio = (e, { name,value }) => {
        const { newlearningpath } = { ...this.state };
        const currentState = newlearningpath;
        currentState[name] = value;
        this.setState({ newlearningpath: currentState });
        this.setState({ newlearningpath_updateready: true })
    };

    async onClick_createlearningpath(){
        this.setState({ newlearningpath_updating: true });
        this.insertlearningpath();
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Learning Paths</div>
                    <Button onClick={() => this.onOpen_mdl_createlearningpath()}>NEW LEARNING PATH</Button>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_landingpages">
                        <div className="content-card-header">
                            Learning Paths
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_learningpathstatus"></Table.HeaderCell>
                                        <Table.HeaderCell>LEARNING PATH NAME</Table.HeaderCell>
                                        <Table.HeaderCell>DESCRIPTION</Table.HeaderCell>
                                        <Table.HeaderCell>SHORT DESCRIPTION</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>BLOCKS</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.learningpaths.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.id)}>
                                            <Table.Cell className="tbl_learningpathstatus">{item.status === 'ACTIVE' && <img className="table_statusicon" src="/icons/checkmark (3dbf1c).svg" alt="checkmark icon"></img>}</Table.Cell>
                                            <Table.Cell>{item.learningpath_name}</Table.Cell>
                                            <Table.Cell>{item.description && item.description.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell>{item.description_short && item.description_short.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.blocks}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <Modal className="mdl_createlearningpath"
                       dimmer={'inverted'}
                       open={this.state.mdl_createlearningpath_open}
                       onClose={() => { this.setState({ mdl_createlearningpath_open: false }) }}
                >
                    <div className="modal-header">Create A New Learning Path</div>
                    <div className="modal-content">
                        <Form onSubmit={this.onSubmit_mdl_createtopic}
                        >
                            <Label>LEARNING PATH STATUS</Label>
                            <Form.Group>
                                <Form.Field
                                    control={Radio}
                                    label='Active'
                                    value='ACTIVE'
                                    name='status'
                                    checked={this.state.newlearningpath.status === 'ACTIVE'}
                                    onChange={this.onChange_mdl_createlearningpath_radio}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Inactive'
                                    value='INACTIVE'
                                    name='status'
                                    checked={this.state.newlearningpath.status === 'INACTIVE'}
                                    onChange={this.onChange_mdl_createlearningpath_radio}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='learningpath'
                                    id='learningpath'
                                    name='learningpath'
                                    value={this.state.newlearningpath.block}
                                    control={Input}
                                    label='LEARNING PATH NAME'
                                    placeholder='Learning path name...'
                                    onChange={this.onChange_mdl_createlearningpath_input}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_createlearningpath()}>CANCEL</Button>
                            {!this.state.newlearningpath_updating
                                ?
                                <Button className={(this.state.newlearningpath_updateready ? 'activestate' : '')} onClick={() => this.onClick_createlearningpath()}>CREATE</Button>
                                :
                                <Button loading className='activestate'>CREATE</Button>
                            }
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}