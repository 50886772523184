import React, { Component } from "react";
import "./resource-details.css";
import { API, Storage } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Modal} from 'semantic-ui-react'


export default class resource_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            resource: {},
            resource_updating: false,
            resource_updateready: false,
            resourcetopic_updating: false,
            mdl_removefromtopic_open: false,
            mdl_deleteresource_open: false,
            mdl_deleteresource_updating: false,
            didmount: false
        };

    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateresource()
            this.state.didmount = true;
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
        }
    }


    async populateresource(){
        try {
            let resource = await this.API_get_resource();
            resource[0]['lastmodifiedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ resource: resource[0] });
            this.setState({ resource_updating: false });
            this.setState({ resource_updateready: false });
        } catch (e) {
            console.log(e);
        }
    }

    async updateresource(){
        try {
            await this.API_post_resource(this.state.resource);
            this.populateresource()
        } catch (e) {
            console.log(e);
        }
    }

    async deleteresourcetopic(topic_id){
        try {
            const { resource } = { ...this.state };
            const currentState = resource;
            currentState['topic_id'] = '0';
            currentState['topic'] = '';
            this.setState({ resource: currentState });

            await this.API_post_resource(this.state.resource);
            this.setState({ resourcetopic_updating: false });
            this.setState({ mdl_removefromtopic_open: false });
            this.populateresource()
        } catch (e) {
            console.log(e);
        }
    }


    API_get_resource() {
        return API.get('tap-admin', '/resources/get-resource/'+this.props.match.params.resourceid);
    }

    API_post_resource(body) {
        return API.post('tap-admin', '/resources/post-resource/'+this.props.match.params.resourceid, { body });
    }

    API_delete_resource() {
        return API.del('tap-admin', '/resources/delete-resource/'+this.props.match.params.resourceid);
    }

    onClick_btn_detailparent(){
        this.props.history.push('/resources');
    }


    handleChange(event) {
        console.log(event)
        //this.setState({value: event.target.value});
    }

    onChange_input = (event) => {
        const { resource } = { ...this.state };
        const currentState = resource;
        currentState[event.target.name] = event.target.value;
        this.setState({ resource: currentState });
        this.setState({ resource_updateready: true })
    };

    onChange_radio = (e, { name,value }) => {
        const { resource } = { ...this.state };
        const currentState = resource;
        currentState[name] = value;
        this.setState({ resource: currentState });
        this.setState({ resource_updateready: true })
    };

    onClick_removefromtopic(topic_id){
        this.setState({ mdl_removefromtopic_open: true })
    }

    onClick_viewtopic(topic_id){
        this.props.history.push('/topic-details/'+topic_id);
    }

    onClick_cancel(){
        this.props.history.push('/resources');
    }

    onClick_save(){
        if(this.state.resource_updateready) {
            this.setState({resource_updating: true})
            this.updateresource();
        }
    }

    onClick_delete() {
        this.setState({mdl_deleteresource_updating: false});
        this.setState({mdl_deleteresource_open: true});
    }

    async onClick_deleteresource(){
        this.setState({mdl_deleteresource_updating: true})
        try {
            await Storage.remove(this.state.resource.filetype + '/'+this.state.resource.filename );
            await this.API_delete_resource();
            this.setState({mdl_deleteresource_updating: false})
            this.setState({mdl_deleteresource_open: false});
            this.props.history.push('/resources');
        } catch (e) {
            alert(e);
            this.setState({mdl_deleteresource_updating: false})
            this.setState({mdl_deleteresource_open: false});
        }
    }


    onClose_mdl_removefromtopic(){
        this.setState({ mdl_removefromtopic_open: false })
    }

    onClick_deleteresourcetopic(topic_id){
        this.setState({resourcetopic_updating: true})
        this.deleteresourcetopic(topic_id);
    }

    close = () => this.setState({ open: false })
    show = dimmer => () => this.setState({ dimmer, open: true })

    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu" id="resource-details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Resources</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_resourcedetails">
                        <div className="content-card-header">
                            {this.state.resource.filename}
                        </div>
                        {this.state.resource &&
                        <div className="resource content-card-content">
                            <Form onSubmit={this.handleSubmitForm}
                            >
                                <Label>RESOURCE STATUS</Label>
                                <Form.Group>
                                    <Form.Field
                                        control={Radio}
                                        label='Active'
                                        value='ACTIVE'
                                        name='status'
                                        checked={this.state.resource.status === 'ACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='Inactive'
                                        value='INACTIVE'
                                        name='status'
                                        checked={this.state.resource.status === 'INACTIVE'}
                                        onChange={this.onChange_radio}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='filename'
                                        id='filename'
                                        name='filename'
                                        value={this.state.resource.filename}
                                        control={Input}
                                        label='FILE NAME'
                                        placeholder='File Name'
                                        readOnly
                                    />
                                    <Form.Field
                                        className='filetype'
                                        id='filetype'
                                        name='filetype'
                                        value={this.state.resource.filetype}
                                        control={Input}
                                        label='FILE TYPE'
                                        placeholder=''
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        className='topic'
                                        id='topic'
                                        name='topic'
                                        value={this.state.resource.topic}
                                        control={Input}
                                        label='TOPIC'
                                        placeholder='this resource has not been assigned to a topic yet'
                                        readOnly
                                    />
                                    {this.state.resource.topic_id &&
                                        <div className="btn_deletedditem" onClick={() => this.onClick_removefromtopic(this.state.resource.topic_id)}><img src="/icons/x (e22d30).svg" alt="delete item icon"></img></div>
                                    }
                                    {this.state.resource.topic_id &&
                                        <div className="btn_viewdditem" onClick={() => this.onClick_viewtopic(this.state.resource.topic_id)}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                                    }
                                </Form.Group>
                            </Form>
                        </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.resource.lastmodifiedby} - {this.state.resource.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button onClick={() => this.onClick_cancel(this.state.resource.block_id)}>CANCEL</Button>
                                {!this.state.resource.topic_id &&
                                    <Button onClick={() => this.onClick_delete(this.state.resource.block_id)}>DELETE</Button>
                                }
                                {!this.state.resource_updating
                                    ?
                                    <Button className={(this.state.resource_updateready ? 'activestate' : '')} onClick={() => this.onClick_save()}>SAVE</Button>
                                    :
                                    <Button loading className='activestate'>SAVE</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/**    MODAL: ADD TOPIC *************************************************************************************/}

                <Modal id="mdl_removefromtopic"
                       dimmer={'inverted'}
                       open={this.state.mdl_removefromtopic_open}
                       onClose={() => { this.setState({ mdl_removefromtopic_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove this resource from a topic</div>
                    <div className="modal-content">
                        Do you really want to remove '{this.state.resource.filename}' from the topic '{this.state.resource.topic}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removefromtopic()}>NO</Button>
                            {!this.state.resourcetopic_updating
                                ?   <Button className={(this.state.blocktopic_updateready ? 'activestate' : '')} onClick={() => this.onClick_deleteresourcetopic(this.state.resource.topic_id)}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: ADD TOPIC *********************************************************************************/}

                {/**    MODAL: DELETE RESOURCE ***********************************************************************/}

                <Modal id="mdl_deleteresource"
                       dimmer={'inverted'}
                       open={this.state.mdl_deleteresource_open}
                       onClose={() => { this.setState({ mdl_deleteresource_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Delete this resource</div>
                    <div className="modal-content">
                        Do you really want to delete this resource?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.setState({ mdl_deleteresource_open: false })}>NO</Button>
                            {!this.state.deleteresource_updating
                                ?   <Button className='activestate' onClick={() => this.onClick_deleteresource(this.state.resource.topic_id)}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: DELETE RESOURCE *******************************************************************/}
            </div>
        );
    }
}