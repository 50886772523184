import React, { Component } from "react";
import "./members.css";
import { API } from "aws-amplify";
import {Table} from 'semantic-ui-react'


export default class members extends Component {

    constructor(props) {
        super(props);

        this.state = {
            members: [],
        };
    }


    async componentDidMount() {
            this.populatemembers();
    }


    async populatemembers(){
        try {
            let members = await this.API_get_members();
            console.log('loaded')
            this.setState({ members });
        } catch (e) {
            console.log(e);
        }
    }

    API_get_members() {
        return API.get("tap-admin", "/users/get-members");
    }



    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Members</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card">
                        <div className="content-card-header">
                            All Members
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>FIRST NAME</Table.HeaderCell>
                                        <Table.HeaderCell>LAST NAME</Table.HeaderCell>
                                        <Table.HeaderCell>EMAIL</Table.HeaderCell>
                                        <Table.HeaderCell>CREATED</Table.HeaderCell>
                                        <Table.HeaderCell>STATUS</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.members.map((item,i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.firstname}</Table.Cell>
                                            <Table.Cell>{item.lastname}</Table.Cell>
                                            <Table.Cell>{item.email}</Table.Cell>
                                            <Table.Cell>{item.created}</Table.Cell>
                                            <Table.Cell>{item.status}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}