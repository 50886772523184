import React, { Component } from "react";
import "./resources.css";
import { API, Storage } from "aws-amplify";
import {Button, Form, Label, Modal, Progress, Radio, Table} from 'semantic-ui-react'


export default class resources extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            resources: [],
            newresource: {},
            mdl_createresource_open: false,
            newresource_updating: false,
            newresource_updateready: false,
            newresource_updateprogress: 0,
            didmount: false
        };

        this.file = null;
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateresources();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populateresources(){
        try {
            let resources = await this.API_get_resources();
            this.setState({ resources });

        } catch (e) {
            console.log(e);
        }
    }

    async insertresource(){
        try {
            await this.API_put_resource(this.state.newresource);
            this.setState({ newresource_updating: false });
            this.setState({ newresource_updateready: false });
            this.onClose_mdl_createresource();
            this.populateresources();
        } catch (e) {
            console.log(e);
        }
    }

    API_get_resources() {
        return API.get('tap-admin', '/resources/get-resources');
    }

    API_put_resource(body) {
        return API.put('tap-admin', '/resources/put-resource/', { body });
    }


    tableRowClickFunc(detailid) {
        this.props.history.push('/resource-details/'+detailid);
    }

    onOpen_mdl_createresource(){
        const { newresource } = { ...this.state };
        const currentState = newresource;
        currentState['filetype'] = 'pdf';
        currentState['status'] = 'ACTIVE';
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newresource: currentState });
        this.setState({ newresource_updateready: false });
        this.setState({ mdl_createresource_open: true });
        this.setState({ newresource_updateprogress: 0 });
        this.setState({ filename: '' });
    }

    onClose_mdl_createresource(){
        this.setState({ mdl_createresource_open: false })
    }

    onChange_radio = (e, { name,value }) => {
        const { newresource } = { ...this.state };
        const currentState = newresource;
        currentState[name] = value;
        this.setState({ newresource: currentState });
        this.setState({ newresource_updateready: true })
    };

    onChange_file = event => {
        this.file = event.target.files[0];
        this.setState({ filename: this.file.name });
        this.setState({ newresource_updateready: true });
    };



    async onClick_createresource(){
        this.setState({ newresource_updating: true });

        const CustomFunc = progress => {
            this.setState({ newresource_updateprogress: Math.round((progress.loaded/progress.total)*100) });
        };

        try {
            await Storage.put(this.state.newresource.filetype + '/'+this.file.name, this.file, {
                progressCallback: progress => {
                    CustomFunc(progress);
                },
            });
            const { newresource } = { ...this.state };
            const currentState = newresource;
            currentState['filename'] = this.file.name;
            this.setState({ newresource: currentState });
            this.insertresource();
        } catch (e) {
            alert(e);
            this.setState({ newresource_updating: false });
        }
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Resources</div>
                    <Button onClick={() => this.onOpen_mdl_createresource()}>NEW RESOURCE</Button>
                </div>
                <div className="content-wrapper">
                    <div className="content-card">
                        <div className="content-card-header">
                            Resources
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_resourcestatus"></Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_resourcestatus">DFLT</Table.HeaderCell>
                                        <Table.HeaderCell>FILE NAME</Table.HeaderCell>
                                        <Table.HeaderCell>TYPE</Table.HeaderCell>
                                        <Table.HeaderCell>TOPIC</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>VIEWS</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>DWNLDS</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.resources.map((item, i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.id)}>
                                            <Table.Cell className="tbl_resourcestatus">{item.status === 'ACTIVE' && <img className="table_statusicon" src="/icons/checkmark (3dbf1c).svg" alt="checkmark icon"></img>}</Table.Cell>
                                            <Table.Cell className="tbl_resourcestatus">{item.defaultresource > 0 && <img className="table_statusicon" src="/icons/checkmark (0c6ff2).svg" alt="checkmark icon"></img>}</Table.Cell>
                                            <Table.Cell>{item.filename}</Table.Cell>
                                            <Table.Cell>{item.filetype}</Table.Cell>
                                            <Table.Cell>{item.topic}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.views}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.downloads}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <Modal className="mdl_createresource"
                       dimmer={'inverted'}
                       open={this.state.mdl_createresource_open}
                       onClose={() => { this.setState({ mdl_createresource_open: false }) }}
                >
                    <div className="modal-header">Create A New Resource</div>
                    <div className="modal-content">
                        {!this.state.newresource_updating ?
                            <Form>
                                <Label>FILE TYPE</Label>
                                <Form.Group>
                                    <Form.Field
                                        control={Radio}
                                        label='pdf'
                                        value='pdf'
                                        name='filetype'
                                        checked={this.state.newresource.filetype === 'pdf'}
                                        onChange={this.onChange_radio}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='video'
                                        value='mp4'
                                        name='filetype'
                                        checked={this.state.newresource.filetype === 'mp4'}
                                        onChange={this.onChange_radio}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='audio'
                                        value='mp3'
                                        name='filetype'
                                        checked={this.state.newresource.filetype === 'mp3'}
                                        onChange={this.onChange_radio}
                                    />
                                </Form.Group>
                                <Label>FILE</Label>
                                <div id="btn_filebrowse" className="ui action input">
                                    <input type="text" placeholder="Resource to upload..." value={this.state.filename}
                                           readOnly/>
                                    <button className="ui button" onClick={e => {
                                        this.upload.value = null;
                                        this.upload.click();
                                    }} loading={this.state.uploading}>
                                        BROWSE
                                    </button>
                                </div>
                                <input
                                    type="file"
                                    style={{display: "none"}}
                                    ref={ref => (this.upload = ref)}
                                    onChange={this.onChange_file}
                                />
                            </Form>
                        :
                            <Progress className="mdl_createresource_progressbar" percent={this.state.newresource_updateprogress} progress/>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_createresource()}>CANCEL</Button>
                            {!this.state.newresource_updating
                                ?
                                <Button className={(this.state.newresource_updateready ? 'activestate' : '')} onClick={() => this.onClick_createresource()}>CREATE</Button>
                                :
                                <Button loading className='activestate'>CREATE</Button>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}