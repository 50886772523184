import React, { Component } from "react";
import "./modules.css";
import { API } from "aws-amplify";
import {Button, Form, Input, Label, Modal, Radio, Table} from 'semantic-ui-react'


export default class modules extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules: [],
            didmount: false,
            newmodule: {},
            mdl_createmodule_open: false,
            newmodule_updating: false,
            newmodule_updateready: false
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populatemodules();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populatemodules(){
        try {
            let modules = await this.API_get_modules();
            this.setState({ modules });
        } catch (e) {
            console.log(e);
        }
    }

    async insertmodule(){
        try {
            let result_put_module = await this.API_put_module(this.state.newmodule);
            this.setState({ newmodule_updating: false });
            this.setState({ newmodule_updateready: false });
            this.onClose_mdl_createmodule();
            this.props.history.push('/module-details/'+result_put_module['insertId']);
        } catch (e) {
            console.log(e);
        }
    }

    API_put_module(body) {
        return API.put('tap-admin', '/modules/put-module/', { body });
    }

    API_get_modules() {
        return API.get('tap-admin', '/modules/get-modules');
    }

    tableRowClickFunc(detailid) {
        this.props.history.push('/module-details/'+detailid);
    }

    onOpen_mdl_createmodule(){
        const { newmodule } = { ...this.state };
        const currentState = newmodule;
        currentState['status'] = 'ACTIVE';
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newmodule: currentState });
        this.setState({ newmodule_updateready: false });
        this.setState({ mdl_createmodule_open: true });
    }

    onClose_mdl_createmodule(){
        this.setState({ mdl_createmodule_open: false })
    }

    onChange_mdl_createmodule_input = (event) => {
        const { newmodule } = { ...this.state };
        const currentState = newmodule;
        currentState[event.target.name] = event.target.value;
        this.setState({ newmodule: currentState });
        this.setState({ newmodule_updateready: true })
    };

    onChange_mdl_createmodule_radio = (e, { name,value }) => {
        const { newmodule } = { ...this.state };
        const currentState = newmodule;
        currentState[name] = value;
        this.setState({ newmodule: currentState });
        this.setState({ newmodule_updateready: true })
    };

    async onClick_createmodule(){
        this.setState({ newmodule_updating: true });
        this.insertmodule();
    }

    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Modules</div>
                    <Button onClick={() => this.onOpen_mdl_createmodule()}>NEW MODULE</Button>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_modules">
                        <div className="content-card-header">
                            Modules
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_modulestatus"></Table.HeaderCell>
                                        <Table.HeaderCell>MODULE NAME</Table.HeaderCell>
                                        <Table.HeaderCell>DESCRIPTION</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>BLOCKS</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>VIEWS</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.modules.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.id)}>
                                            <Table.Cell className="tbl_modulestatus">{item.status === 'ACTIVE' && <img className="table_statusicon" src="/icons/checkmark (3dbf1c).svg" alt="checkmark icon"></img>}</Table.Cell>
                                            <Table.Cell>{item.module_name}</Table.Cell>
                                            <Table.Cell>{item.description && item.description.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.blocks}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.views}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <Modal className="mdl_createmodule"
                       dimmer={'inverted'}
                       open={this.state.mdl_createmodule_open}
                       onClose={() => { this.setState({ mdl_createmodule_open: false }) }}
                >
                    <div className="modal-header">Create A New Module</div>
                    <div className="modal-content">
                        <Form onSubmit={this.onSubmit_mdl_createtopic}
                        >
                            <Label>MODULE STATUS</Label>
                            <Form.Group>
                                <Form.Field
                                    control={Radio}
                                    label='Active'
                                    value='ACTIVE'
                                    name='status'
                                    checked={this.state.newmodule.status === 'ACTIVE'}
                                    onChange={this.onChange_mdl_createmodule_radio}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Inactive'
                                    value='INACTIVE'
                                    name='status'
                                    checked={this.state.newmodule.status === 'INACTIVE'}
                                    onChange={this.onChange_mdl_createmodule_radio}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='module'
                                    id='module'
                                    name='module'
                                    value={this.state.newmodule.block}
                                    control={Input}
                                    label='MODULE NAME'
                                    placeholder='Module name...'
                                    onChange={this.onChange_mdl_createmodule_input}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_createmodule()}>CANCEL</Button>
                            {!this.state.newmodule_updating
                                ?
                                <Button className={(this.state.newmodule_updateready ? 'activestate' : '')} onClick={() => this.onClick_createmodule()}>CREATE</Button>
                                :
                                <Button loading className='activestate'>CREATE</Button>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}