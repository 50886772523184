import React, { Component } from "react";
import "./landingpages.css";
import { API } from "aws-amplify";
import { Table } from 'semantic-ui-react'


export default class landingpages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            landingpages: [],
            didmount: false
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populatelandingpages()
            this.state.didmount = true;
        }
    }


    async populatelandingpages(){
        try {
            let landingpages = await this.API_get_landingpages();
            this.setState({ landingpages });
        } catch (e) {
            console.log(e);
        }
    }


    API_get_landingpages() {
        return API.get('tap-admin', '/landingpages/get-landingpages');
    }

    tableRowClickFunc(detailid) {
        this.props.history.push('/landingpage-details/'+detailid);
    }





    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Landing Pages</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_landingpages">
                        <div className="content-card-header">
                            Landing Pages
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>LANDING PAGE</Table.HeaderCell>
                                        <Table.HeaderCell>DESCRIPTION</Table.HeaderCell>
                                        <Table.HeaderCell>LEARNING PATH</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>MODULES</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.landingpages.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.id)}>
                                            <Table.Cell>{item.landingpage_name}</Table.Cell>
                                            <Table.Cell>{item.description && item.description.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell>{item.learningpath_name}</Table.Cell>
                                            <Table.Cell textAlign='right'>{item.modules}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}