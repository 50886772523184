import React from "react";
import { Route, Switch } from "react-router-dom";
import login from "./containers/login/login";
import resources from "./containers/resources/resources";
import resource_details from "./containers/resources/resource-details";
import topics from "./containers/topics/topics";
import topic_details from "./containers/topics/topic-details";
import blocks from "./containers/blocks/blocks";
import block_details from "./containers/blocks/block-details";
import modules from "./containers/modules/modules";
import module_details from "./containers/modules/module-details";
import learningpaths from "./containers/learningpaths/learningpaths";
import learningpath_details from "./containers/learningpaths/learningpath-details";
import landingpages from "./containers/landingpages/landingpages";
import landingpage_details from "./containers/landingpages/landingpage-details";
import blogs from "./containers/blogs/blogs";
import blog_details from "./containers/blogs/blog-details";
import author_details from "./containers/blogs/author-details";
import conversations from "./containers/conversations/conversations";
import members from "./containers/members/members";
import demoaccounts from "./containers/demoaccounts/demoaccounts";
import notfound from "./containers/notfound";
import AppliedRoute from "./components/applied_route";

export default ({ childProps }) =>
    <Switch>
            <AppliedRoute path="/" exact component={login} props={childProps} />
            <AppliedRoute path="/resources" exact component={resources} props={childProps} />
            <AppliedRoute path="/resource-details/:resourceid" exact component={resource_details} props={childProps} />
            <AppliedRoute path="/topics" exact component={topics} props={childProps} />
            <AppliedRoute path="/topic-details/:topicid" exact component={topic_details} props={childProps} />
            <AppliedRoute path="/blocks" exact component={blocks} props={childProps} />
            <AppliedRoute path="/block-details/:blockid" exact component={block_details} props={childProps} />
            <AppliedRoute path="/modules" exact component={modules} props={childProps} />
            <AppliedRoute path="/module-details/:moduleid" exact component={module_details} props={childProps} />
            <AppliedRoute path="/learningpaths" exact component={learningpaths} props={childProps} />
            <AppliedRoute path="/learningpath-details/:learningpathid" exact component={learningpath_details} props={childProps} />
            <AppliedRoute path="/landingpages" exact component={landingpages} props={childProps} />
            <AppliedRoute path="/landingpage-details/:landingpageid" exact component={landingpage_details} props={childProps} />
            <AppliedRoute path="/blogs" exact component={blogs} props={childProps} />
            <AppliedRoute path="/blog-details/:blogid" exact component={blog_details} props={childProps} />
            <AppliedRoute path="/author-details/:authorid" exact component={author_details} props={childProps} />
            <AppliedRoute path="/conversations" exact component={conversations} props={childProps} />
            <AppliedRoute path="/members" exact component={members} props={childProps} />
            <AppliedRoute path="/demoaccounts" exact component={demoaccounts} props={childProps} />
        <Route component={notfound} />
    </Switch>;

