import React, { Component } from "react";
import "./block-details.css";
import { API, Storage } from "aws-amplify";
import {Button, Label, Radio, Form, Input, Dropdown, Table, Modal, Progress, TextArea} from 'semantic-ui-react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../react-draft-wysiwyg.css';

export default class block_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: {},
            blocktopic: {},
            topicorderdd: [],
            blocktopics: [],
            blockmodules: [],
            blocklearningpaths: [],
            topicsdd: [],
            editorState: EditorState.createEmpty(),
            mdl_addtopic_open: false,
            didmount: false,
            block_updating: false,
            block_updateready: false,
            blocktopic_updating: false,
            blocktopic_updateready: false,
            mdl_removetopicfromblock_open: false,
            removetopicfromblock_updating: false,
            removetopicfromblock_updateready: false,
            removetopic: '',
            removetopicid: '',
            newblockimage: {},
            newblockimage_updateready: false,
            mdl_updateblockimage_open: false,
            newblockimage_updating: false,
            mdl_deleteblock_updating: false,
            mdl_deleteblock_open: false,
            newblockimage_updateprogress: 0,
            filename: ''
        };

    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateblock();
            this.populateblocktopics();
            this.populateblockmodules();
            this.populateblocklearningpaths();
            this.populatetopicsdd();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }

    async populateblock(){
        try {
            let block = await this.API_get_block();
            block[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
            block[0]['imageid']=this.props.match.params.blockid;
            this.setState({ block: block[0] });
            if(this.state.block.content) {
                this.setState({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.state.block.content).contentBlocks)) });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updateblock(){
        try {
            await this.API_post_block(this.state.block);
            this.setState({ block_updating: false });
            this.setState({ block_updateready: false });
            this.populateblock();
        } catch (e) {
            console.log(e);
        }
    }

    async updateblockimage(){
        try {
            await this.API_post_blockimage(this.state.newblockimage);
            this.setState({ newblockimage_updating: false });
            this.setState({ newblockimage_updateready: false });
            this.populateblockimage();
        } catch (e) {
            console.log(e);
        }
    }

    async populateblockimage(){
        try {
            let newblockimage = await this.API_get_blockimage();
            this.setState({ newblockimage: newblockimage[0] });
            const { block } = { ...this.state };
            const currentState = block;
            currentState['lastmodified'] = this.state.newblockimage.lastmodified;
            currentState['lastmodifiedby'] = this.state.newblockimage.lastmodifiedby;
            currentState['imageid']=this.props.match.params.blockid;
            this.setState({ block: currentState });
            document.getElementById('blockimage').src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/block_"+this.props.match.params.blockid+".jpg";
            this.setState({ mdl_updateblockimage_open: false })
        } catch (e) {
            console.log(e);
        }
    }

    async populateblocktopics(){
        try {
            let blocktopics = await this.API_get_blocktopics();
            this.setState({ blocktopics });

            let temptopicorderdd = [];
            for(let i = 1; i < blocktopics.length + 2; i++){
                temptopicorderdd.push({value:i,text:i})
            }
            this.setState({ topicorderdd: temptopicorderdd });
        } catch (e) {
            console.log(e);
        }
    }

    async populateblocktopic(topic_id){
        let blocktopic = await this.API_get_blocktopic(topic_id);
        blocktopic[0]['updatedby']=this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ blocktopic: blocktopic[0] });
    }

    async insertblocktopic(){
        try {
            const { blocktopic } = { ...this.state };
            const currentState = blocktopic;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ blocktopic: currentState });

            await this.API_put_blocktopic(this.state.blocktopic);
            this.setState({ blocktopic_updating: false });
            this.setState({ blocktopic_updateready: false });
            this.onClose_mdl_addtopic();
            this.populateblocktopics();
        } catch (e) {
            console.log(e);
        }
    }

    async onClick_updateblocktopic(){
        this.setState({ blocktopic_updating: true });
        try {
            const { blocktopic } = { ...this.state };
            const currentState = blocktopic;
            currentState['updatedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
            this.setState({ blocktopic: currentState });

            await this.API_post_blocktopic(this.state.blocktopic);
            this.setState({ blocktopic_updating: false });
            this.setState({ blocktopic_updateready: false });
            this.onClose_mdl_addtopic();
            this.populateblocktopics();
        } catch (e) {
            console.log(e);
        }
    }

    async populateblockmodules(){
        try {
            let blockmodules = await this.API_get_blockmodules();
            this.setState({ blockmodules });
        } catch (e) {
            console.log(e);
        }
    }

    async populateblocklearningpaths(){
        try {
            let blocklearningpaths = await this.API_get_blocklearningpaths();
            this.setState({ blocklearningpaths });
        } catch (e) {
            console.log(e);
        }
    }

    async populatetopicsdd(){
        try {
            let topicsdd = await this.API_get_topicsdd();
            this.setState({ topicsdd });
        } catch (e) {
            console.log(e);
        }
    }

    API_get_block() {
        return API.get('tap-admin', '/blocks/get-block/'+this.props.match.params.blockid);
    }

    API_get_blockimage() {
        return API.get('tap-admin', '/blocks/get-blockimage/'+this.props.match.params.blockid);
    }

    API_post_block(body) {
        return API.post('tap-admin', '/blocks/post-block/'+this.props.match.params.blockid, { body });
    }

    API_delete_block() {
        return API.del('tap-admin', '/blocks/delete-block/'+this.props.match.params.blockid);
    }

    API_post_blockimage(body) {
        return API.post('tap-admin', '/blocks/post-blockimage/'+this.props.match.params.blockid, { body });
    }

    API_get_blocktopics() {
        return API.get('tap-admin', '/blocks/get-blocktopics/'+this.props.match.params.blockid);
    }

    API_del_blocktopic() {
        return API.del('tap-admin', '/blocks/del-blocktopic/'+this.state.removetopicid);
    }

    API_get_blocktopic(topic_id) {
        return API.get('tap-admin', '/blocks/get-blocktopic/'+topic_id);
    }

    API_put_blocktopic(body) {
        return API.put('tap-admin', '/blocks/put-blocktopic/'+this.props.match.params.blockid, { body });
    }

    API_post_blocktopic(body) {
        return API.post('tap-admin', '/blocks/post-blocktopic/'+this.state.blocktopic.id, { body });
    }

    API_get_blockmodules() {
        return API.get('tap-admin', '/blocks/get-blockmodules/'+this.props.match.params.blockid);
    }

    API_get_blocklearningpaths() {
        return API.get('tap-admin', '/blocks/get-blocklearningpaths/'+this.props.match.params.blockid);
    }

    API_get_topicsdd() {
        return API.get('tap-admin', '/topics/get-topicsdd');
    }

    onClick_btn_detailparent(){
        this.props.history.push('/blocks');
    }

    onChange_editor = (editorState) => {
        this.setState({ editorState });
        const { block } = { ...this.state };
        const currentState = block;
        currentState['content'] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState({ block: currentState });
        this.setState({ block_updateready: true })
    };

    onChange_radio = (e, { name,value }) => {
        const { block } = { ...this.state };
        const currentState = block;
        currentState[name] = value;
        this.setState({ block: currentState });
        this.setState({ block_updateready: true })
    };

    onChange_input = (event) => {
        const { block } = { ...this.state };
        const currentState = block;
        currentState[event.target.name] = event.target.value;
        this.setState({ block: currentState });
        this.setState({ block_updateready: true })
    };

    onChange_dropdown = (e, { name,value }) => {
        const { blocktopic } = { ...this.state };
        const currentState = blocktopic;
        currentState[name] = value;
        this.setState({ blocktopic: currentState });
        this.setState({ blocktopic_updateready: true })
    };

    onOpen_mdl_updateblockimage(){
        const { newblockimage } = { ...this.state };
        const currentState = newblockimage;
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newblockimage: currentState });
        this.setState({ newblockimage_updateready: false });
        this.setState({ mdl_updateblockimage_open: true });
        this.setState({ newblockimage_updateprogress: 0 });
        this.setState({ filename: '' });
    }

    onChange_file = event => {
        this.file = event.target.files[0];
        this.setState({ filename: this.file.name });
        this.setState({ newblockimage_updateready: true });
    };

    async onClick_updateblockimage(){
        this.setState({ newblockimage_updating: true });
        document.getElementById('blockimage').src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg";

        const CustomFunc = progress => {
            this.setState({ newblockimage_updateprogress: Math.round((progress.loaded/progress.total)*100) });
        };

        try {
            await Storage.put('jpg/block_'+this.state.block.id+'.jpg', this.file, {
                progressCallback: progress => {
                    CustomFunc(progress);
                },
            });
            this.updateblockimage();
        } catch (e) {
            alert(e);
            this.setState({ newblockimage_updating: false });
        }
    }

    onClose_mdl_updateblockimage(){
        this.setState({ mdl_updateblockimage_open: false })
    }

    onClick_viewtopic(topic_id){
        this.props.history.push('/topic-details/'+topic_id);
    }

    onClick_viewmodule(module_id){
        this.props.history.push('/module-details/'+module_id);
    }

    onClick_viewlearningpath(learningpath_id){
        this.props.history.push('/learningpath-details/'+learningpath_id);
    }

    onClick_edittopic(topic_id){
        this.setState({ mdl_addtopic_mode: 'UPDATE' })
        this.setState({ blocktopic_updateready: false });
        this.setState({ mdl_addtopic_open: true })
        this.populateblocktopic(topic_id)
    }

    onOpen_mdl_addtopic(){
        const { blocktopic } = { ...this.state };
        const currentState = blocktopic;
        currentState['topic_id'] = '0';
        currentState['topic_order'] = '';
        this.setState({ blocktopic: currentState });

        this.setState({ mdl_addtopic_mode: 'INSERT' })
        this.setState({ blocktopic_updateready: false });
        this.setState({ mdl_addtopic_open: true });
    }

    onClose_mdl_addtopic(){
        this.setState({ mdl_addtopic_open: false })
    }


    onClick_btn_viewblock(){
        window.open('https://tapadvance.net/block/'+this.props.match.params.blockid, "_blank")
    }

    onClick_removetopicfromblock(event,removetopicid,removetopic){
        event.stopPropagation();
        this.setState({ removetopic: removetopic });
        this.setState({ removetopicid: removetopicid });
        this.setState({ removetopicfromblock_updateready: true })
        this.setState({ mdl_removetopicfromblock_open: true })
    }

    async onClick_deletetopicfromblock(){
        this.setState({ removetopicfromblock_updating: true });
        try {
            await this.API_del_blocktopic();
            this.setState({ removetopicfromblock_updating: false });
            this.setState({ removetopicfromblock_updateready: false });
            this.setState({ mdl_removetopicfromblock_open: false })
            this.populateblocktopics();
        } catch (e) {
            console.log(e);
        }
    }

    onClose_mdl_removetopicfromblock(){
        this.setState({ mdl_removetopicfromblock_open: false })
    }


    async onClick_updateblock(){
        this.setState({ block_updating: true });
        this.updateblock();
    }

    async onClick_insertblocktopic(){
        this.setState({ blocktopic_updating: true });
        this.insertblocktopic();
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }

    onClick_delete(){
        this.setState({mdl_deleteblock_updating: false});
        this.setState({mdl_deleteblock_open: true});
    }

    async onClick_deleteblock(){
        this.setState({mdl_deleteblock_updating: true})
        try {
            await this.API_delete_block();
            this.setState({mdl_deleteblock_updating: false})
            this.setState({mdl_deleteblock_open: false});
            this.props.history.push('/blocks');
        } catch (e) {
            alert(e);
            this.setState({mdl_deleteblock_updating: false})
            this.setState({mdl_deleteblock_open: false});
        }
    }



    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        const { editorState } = this.state;
        return (
            <div className="content">
                <div className="content-topmenu" id="block_details">
                    <div className="btn_detailparent" onClick={() => this.onClick_btn_detailparent()}>Blocks</div><div className="content-topmenu-detailname">Details</div>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_blockdetails">
                        <div className="content-card-header">
                            {this.state.block.block_name}
                            <div className="content-card-header-buttons" onClick={() => this.onClick_btn_viewblock()}><img src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></div>
                        </div>
                        {this.state.block &&
                            <div className="resource content-card-content">
                                <Form>
                                    <Label>BLOCK STATUS</Label>
                                    <Form.Group>
                                        <Form.Field
                                            control={Radio}
                                            label='Active'
                                            value='ACTIVE'
                                            name='status'
                                            checked={this.state.block.status === 'ACTIVE'}
                                            onChange={this.onChange_radio}
                                        />
                                        <Form.Field
                                            control={Radio}
                                            label='Inactive'
                                            value='INACTIVE'
                                            name='status'
                                            checked={this.state.block.status === 'INACTIVE'}
                                            onChange={this.onChange_radio}
                                        />
                                    </Form.Group>
                                    <Label>BLOCK IMAGE (CLICK TO UPDATE)</Label>
                                    <Form.Group>
                                        <div className="blockimage">
                                            <img id="blockimage"
                                                 src={"https://tap-app-resources.s3.amazonaws.com/public/jpg/block_"+this.state.block.imageid+".jpg"}
                                                 onError={this.addDefaultSrc}
                                                 onClick={() => this.onOpen_mdl_updateblockimage()}
                                                 alt="block header"></img>
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Field
                                            className='block'
                                            id='block'
                                            name='block_name'
                                            value={this.state.block.block_name || ''}
                                            control={Input}
                                            label='BLOCK NAME'
                                            placeholder='Block name...'
                                            onChange={this.onChange_input}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Field
                                            className='block'
                                            id='description_short'
                                            name='description_short'
                                            value={this.state.block.description_short || ''}
                                            control={TextArea}
                                            label='DESCRIPTION (SHORT)'
                                            placeholder='Short description...'
                                            onChange={this.onChange_input}
                                        />
                                    </Form.Group>
                                    <Label>DESCRIPTION</Label>
                                    <div>
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            toolbarClassName="demo-toolbar"
                                            onEditorStateChange={this.onChange_editor}
                                            defaultEditorState={this.state.block.content}
                                            toolbar={{
                                                options: ['inline', 'history'],
                                                inline: {options: ['bold', 'italic', 'underline',]},
                                            }}
                                        />
                                    </div>
                                </Form>
                            </div>
                        }
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified">last modified by: {this.state.block.lastmodifiedby} - {this.state.block.lastmodified}</div>
                            <div className="content-card-footer-buttons">
                                <Button >CANCEL</Button>
                                {(this.state.blocktopics.length === 0 && this.state.blockmodules.length === 0 && this.state.blocklearningpaths.length === 0) &&
                                    <Button onClick={() => this.onClick_delete()}>DELETE</Button>
                                }
                                {!this.state.block_updating
                                    ?
                                    <Button className={(this.state.block_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateblock()}>UPDATE</Button>
                                    :
                                    <Button loading className='activestate'>UPDATE</Button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="content-card" id="cc_blocktopics">
                        <div className="content-card-header">
                            Topics assigned to this block:
                        </div>
                        <div className="resource content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_order">ORDER</Table.HeaderCell>
                                        <Table.HeaderCell>TOPIC</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.blocktopics.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.onClick_edittopic(item.id)}>
                                            <Table.Cell className="tbl_order">{item.topic_order}</Table.Cell>
                                            <Table.Cell>{item.topic}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={event => this.onClick_removetopicfromblock(event,item.id, item.topic)} ><img className="table_statusicon" src="/icons/x (e22d30).svg" alt="delete item icon"></img></Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewtopic(item.topic_id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="content-card-footer">
                            <div className="content-card-footer-lastmodified"></div>
                            <div className="content-card-footer-buttons">
                                <Button onClick={() => this.onOpen_mdl_addtopic()}>ADD A TOPIC</Button>
                            </div>
                        </div>
                    </div>

                    <div className="content-card" id="cc_blockmoduleassignment">
                        <div className="content-card-header">
                            Assigned to these modules:
                        </div>
                        <div className="resource content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>MODULE</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.blockmodules.map((item,i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.module_name}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewmodule(item.id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="content-card" id="cc_blocklearningpathassignment">
                        <div className="content-card-header">
                            Assigned to these learning paths:
                        </div>
                        <div className="resource content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>LEARNING PATH</Table.HeaderCell>
                                        <Table.HeaderCell className="tbl_blockstatus"></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.blocklearningpaths.map((item,i) =>
                                        <Table.Row key={i}>
                                            <Table.Cell>{item.learningpath_name}</Table.Cell>
                                            <Table.Cell className="tbl_blockstatus" onClick={() => this.onClick_viewlearningpath(item.id)}><img className="table_statusicon" src="/icons/arrow (0c6ff2).svg" alt="view item icon"></img></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                {/**    MODAL: UPDATE BLOCK IMAGE ********************************************************************/}

                <Modal className="mdl_updateblockimage"
                       dimmer={'inverted'}
                       open={this.state.mdl_updateblockimage_open}
                       onClose={() => { this.setState({ mdl_updateblockimage_open: false }) }}
                >
                    <div className="modal-header">Select A New Image</div>
                    <div className="modal-content">
                        {!this.state.newblockimage_updating ?
                            <Form>
                                <Label>BLOCK IMAGE FILE</Label>
                                <div id="btn_filebrowse" className="ui action input">
                                    <input type="text" placeholder="Image to upload..." value={this.state.filename}
                                           readOnly/>
                                    <button className="ui button" onClick={e => {
                                        this.upload.value = null;
                                        this.upload.click();
                                    }} loading={this.state.uploading}>
                                        BROWSE
                                    </button>
                                </div>
                                <input
                                    type="file"
                                    style={{display: "none"}}
                                    ref={ref => (this.upload = ref)}
                                    onChange={this.onChange_file}
                                />
                            </Form>
                            :
                            <Progress className="mdl_updateblockimage_progressbar"
                                      percent={this.state.newblockimage_updateprogress} progress/>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_updateblockimage()}>CANCEL</Button>
                            {!this.state.newblockimage_updating
                                ?
                                <Button className={(this.state.newblockimage_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateblockimage()}>UPDATE</Button>
                                :
                                <Button loading className='activestate'>UPDATE</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: UPDATE BLOCK IMAGE ****************************************************************/}

                {/**    MODAL: ADD TOPIC *****************************************************************************/}

                <Modal className="mdl_addtopic"
                       dimmer={'inverted'}
                       open={this.state.mdl_addtopic_open}
                       onClose={() => { this.setState({ mdl_addtopic_open: false }) }}
                >
                    {this.state.mdl_addtopic_mode === 'INSERT'
                        ?
                        <div className="modal-header">Add a topic to this block</div>
                        :
                        <div className="modal-header">Update this topic</div>
                    }
                    <div className="modal-content">
                        <Form>
                            <Label>TOPIC ORDER</Label>
                            <Form.Group className="topic_order">
                                <Dropdown
                                    id='topic_order'
                                    placeholder='0'
                                    fluid
                                    selection
                                    value={this.state.blocktopic.topic_order}
                                    name='topic_order'
                                    onChange={this.onChange_dropdown}
                                    options={this.state.topicorderdd}
                                />
                            </Form.Group>
                            <Label>TOPICS</Label>
                            <Form.Group className="dd_topics">
                                <Dropdown
                                    id='dd_topics'
                                    placeholder='select a topic'
                                    fluid
                                    selection
                                    name='topic_id'
                                    value={this.state.blocktopic.topic_id}
                                    onChange={this.onChange_dropdown}
                                    options={this.state.topicsdd}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_addtopic()}>CANCEL</Button>
                            {!this.state.blocktopic_updating
                                ?   (   this.state.mdl_addtopic_mode === 'INSERT'
                                        ?
                                        <Button className={(this.state.blocktopic_updateready ? 'activestate' : '')} onClick={() => this.onClick_insertblocktopic()}>ADD TOPIC</Button>
                                        :
                                        <Button className={(this.state.blocktopic_updateready ? 'activestate' : '')} onClick={() => this.onClick_updateblocktopic()}>UPDATE</Button>
                                )
                                :   (   this.state.mdl_addtopic_mode === 'UPDATE'
                                        ?
                                        <Button loading className='activestate'>ADD TOPIC</Button>
                                        :
                                        <Button loading className='activestate'>UPDATE</Button>
                                )
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: ADD TOPIC *************************************************************************/}

                {/**    MODAL: REMOVE TOPIC FROM BLOCK ***************************************************************/}

                <Modal id="mdl_removetopicfromblock"
                       dimmer={'inverted'}
                       open={this.state.mdl_removetopicfromblock_open}
                       onClose={() => { this.setState({ mdl_removetopicfromblock_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Remove topic from block</div>
                    <div className="modal-content">
                        Do you really want to remove '{this.state.removetopic}' from the block '{this.state.block.block_name}'?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_removetopicfromblock()}>NO</Button>
                            {!this.state.removetopicfromblock_updating
                                ?   <Button className={(this.state.removetopicfromblock_updateready ? 'activestate' : '')} onClick={() => this.onClick_deletetopicfromblock()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: REMOVE RESOURCE FROM TOPIC ********************************************************/}

                {/**    MODAL: DELETE BLOCK **************************************************************************/}

                <Modal id="mdl_deleteblock"
                       dimmer={'inverted'}
                       open={this.state.mdl_deleteblock_open}
                       onClose={() => { this.setState({ mdl_deleteblock_open: false }) }}
                >
                    <div className="modal-header"><img src="/icons/warning (be2020).svg" alt="warning icon"></img>Delete this block</div>
                    <div className="modal-content">
                        Do you really want to delete this block?
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.setState({ mdl_deleteblock_open: false })}>NO</Button>
                            {!this.state.deleteblock_updating
                                ?   <Button className='activestate' onClick={() => this.onClick_deleteblock()}>YES</Button>
                                :   <Button loading className='activestate'>YES</Button>
                            }
                        </div>
                    </div>
                </Modal>

                {/**    END MODAL: DELETE BLOCK **********************************************************************/}

            </div>
        );
    }
}