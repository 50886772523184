import React, { Component } from "react";
import "./blogs.css";
import { API } from "aws-amplify";
import {Button, Form, Input, Label, Modal, Radio, Table} from 'semantic-ui-react'


export default class blogs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blogs: [],
            authors: [],
            didmount: false,
            newblog: {},
            mdl_createblog_open: false,
            newblog_updating: false,
            newblog_updateready: false,
        };
    }


    async componentDidMount() {
        if(this.state.didmount === false) {
            this.populateblogs();
            this.populateauthors();
            this.setState({ user: JSON.parse(localStorage.getItem('user')) });
            this.state.didmount = true;
        }
    }


    async populateblogs(){
        try {
            let blogs = await this.API_get_blogs();
            this.setState({ blogs });
        } catch (e) {
            console.log(e);
        }
    }

    async populateauthors(){
        try {
            let authors = await this.API_get_authors();
            this.setState({ authors });
        } catch (e) {
            console.log(e);
        }
    }

    async insertblog(){
        try {
            let result_put_blog = await this.API_put_blog(this.state.newblog);
            this.setState({ newblog_updating: false });
            this.setState({ newblog_updateready: false });
            this.onClose_mdl_createblog();
            this.props.history.push('/blog-details/'+result_put_blog['insertId']);
        } catch (e) {
            console.log(e);
        }
    }


    API_get_blogs() {
        return API.get('tap-admin', '/blogs/get-blogs');
    }

    API_get_authors() {
        return API.get('tap-admin', '/blogs/get-authors');
    }

    API_put_blog(body) {
        return API.put('tap-admin', '/blogs/put-blog/', { body });
    }

    tableRowClickFunc(detailid) {
        this.props.history.push('/blog-details/'+detailid);
    }

    author_tableRowClickFunc(detailid) {
        this.props.history.push('/author-details/'+detailid);
    }

    onOpen_mdl_createblog(){
        const { newblog } = { ...this.state };
        const currentState = newblog;
        currentState['status'] = 'ACTIVE';
        currentState['lastmodifiedby'] = this.state.user.firstname + ' ' + this.state.user.lastname;
        this.setState({ newblog: currentState });
        this.setState({ newblog_updateready: false });
        this.setState({ mdl_createblog_open: true });
    }

    onClose_mdl_createblog(){
        this.setState({ mdl_createblog_open: false })
    }

    onChange_mdl_createblog_input = (event) => {
        const { newblog } = { ...this.state };
        const currentState = newblog;
        currentState[event.target.name] = event.target.value;
        this.setState({ newblog: currentState });
        this.setState({ newblog_updateready: true })
    };

    onChange_mdl_createblog_radio = (e, { name,value }) => {
        const { newblog } = { ...this.state };
        const currentState = newblog;
        currentState[name] = value;
        this.setState({ newblog: currentState });
        this.setState({ newblog_updateready: true })
    };

    async onClick_createblog(){
        this.setState({ newblog_updating: true });
        this.insertblog();
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }


    //  render login ---------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                <div className="content-topmenu">
                    <div>Blogs</div>
                    <Button onClick={() => this.onOpen_mdl_createblog()}>NEW BLOG</Button>
                </div>
                <div className="content-wrapper">
                    <div className="content-card" id="cc_blogs">
                        <div className="content-card-header">
                            Blogs
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell className="tbl_blogstatus"></Table.HeaderCell>
                                        <Table.HeaderCell>IMAGE</Table.HeaderCell>
                                        <Table.HeaderCell>BLOG NAME</Table.HeaderCell>
                                        <Table.HeaderCell>DESCRIPTION</Table.HeaderCell>
                                        <Table.HeaderCell>AUTHOR</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.blogs.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.tableRowClickFunc(item.id)}>
                                            <Table.Cell className="tbl_blogstatus">{item.status === 'ACTIVE' && <img className="table_statusicon" src="/icons/checkmark (3dbf1c).svg" alt="checkmark icon"></img>}</Table.Cell>
                                            <Table.Cell className="tbl_blockimage"><img src={"https://tap-app-resources.s3.amazonaws.com/public/jpg/blog_"+item.id+".jpg"}
                                                                                        onError={this.addDefaultSrc}
                                                                                        alt="blog header"></img></Table.Cell>
                                            <Table.Cell>{item.blog_name}</Table.Cell>
                                            <Table.Cell>{item.description_short && item.description_short.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell>{item.author}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="content-card" id="cc_authors">
                        <div className="content-card-header">
                            Authors
                        </div>
                        <div className="content-card-content">
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>IMAGE</Table.HeaderCell>
                                        <Table.HeaderCell>NAME</Table.HeaderCell>
                                        <Table.HeaderCell>ROLE</Table.HeaderCell>
                                        <Table.HeaderCell>BIO</Table.HeaderCell>
                                        <Table.HeaderCell>LAST MODIFIED</Table.HeaderCell>
                                        <Table.HeaderCell>MODIFIED BY</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.authors.map((item,i) =>
                                        <Table.Row key={i} onClick={() => this.author_tableRowClickFunc(item.id)}>
                                            <Table.Cell className="tbl_authorimage"><img src={"https://tap-app-resources.s3.amazonaws.com/public/jpg/author_"+item.id+".jpg"}
                                                                                        onError={this.addDefaultSrc}
                                                                                        alt="blog header"></img></Table.Cell>
                                            <Table.Cell>{item.author}</Table.Cell>
                                            <Table.Cell>{item.role}</Table.Cell>
                                            <Table.Cell>{item.bio && item.bio.substring(0,30)+'...'}</Table.Cell>
                                            <Table.Cell>{item.lastmodified}</Table.Cell>
                                            <Table.Cell>{item.lastmodifiedby}</Table.Cell>
                                        </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                </div>

                <Modal className="mdl_createblog"
                       dimmer={'inverted'}
                       open={this.state.mdl_createblog_open}
                       onClose={() => { this.setState({ mdl_createblog_open: false }) }}
                >
                    <div className="modal-header">Create A New Blog</div>
                    <div className="modal-content">
                        <Form onSubmit={this.onSubmit_mdl_createtopic}
                        >
                            <Label>BLOG STATUS</Label>
                            <Form.Group>
                                <Form.Field
                                    control={Radio}
                                    label='Active'
                                    value='ACTIVE'
                                    name='status'
                                    checked={this.state.newblog.status === 'ACTIVE'}
                                    onChange={this.onChange_mdl_createblog_radio}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Inactive'
                                    value='INACTIVE'
                                    name='status'
                                    checked={this.state.newblog.status === 'INACTIVE'}
                                    onChange={this.onChange_mdl_createblog_radio}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field
                                    className='blog'
                                    id='blog_name'
                                    name='blog_name'
                                    value={this.state.newblog.block}
                                    control={Input}
                                    label='BLOG NAME'
                                    placeholder='Blog name...'
                                    onChange={this.onChange_mdl_createblog_input}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer-buttons">
                            <Button onClick={() => this.onClose_mdl_createblog()}>CANCEL</Button>
                            {!this.state.newblog_updating
                                ?
                                <Button className={(this.state.newblog_updateready ? 'activestate' : '')} onClick={() => this.onClick_createblog()}>CREATE</Button>
                                :
                                <Button loading className='activestate'>CREATE</Button>
                            }
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}